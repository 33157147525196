import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import jobService from 'services/jobs.service';
import { IJob } from 'modules/Jobs/typings';
import styles from './Job.module.scss';
import { useRouteMatch } from 'react-router-dom';
import { Grid, Typography, Divider, Box } from '@material-ui/core';
import CandidateJobForm from '../CandidateJobForm';
import KSpinner from 'components/KSpinner';
import dompurify from 'dompurify';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import PlaceIcon from '@material-ui/icons/Place';
import WifiIcon from '@material-ui/icons/Wifi';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { sel_vacancySended } from 'store/vacancy/selectors';
import Page404 from '../Page404';
import { useTranslation } from 'react-i18next';

interface MatchParams {
  jobId: '';
}

const Job = () => {
  const [jobData, setJobData] = useState<IJob | null>(null);
  const { t } = useTranslation();
  const match = useRouteMatch<MatchParams>();
  const history = useHistory();
  const vacancySend = useSelector(sel_vacancySended);

  const getHeightJobContainer = () => {
    const divMain = document.getElementById('jobContainer');
    if (divMain !== null) {
      const heightContainer = divMain.clientHeight;
      window.parent.postMessage(
        {
          id: 'hire_message',
          height: heightContainer,
        },
        `${process.env.REACT_APP_KSQUARE_URL}`,
      );
    }
  };

  useEffect(() => {
    getHeightJobContainer();
    jobService
      .getPublishedById(match.params?.jobId)
      .then((res) => {
        setJobData(res);
        getHeightJobContainer();
      })
      .catch((error) => {
        setJobData(error);
        getHeightJobContainer();
      });

    window.addEventListener('resize', getHeightJobContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isClosed = () => {
    return jobData?.status === 'Closed';
  };

  return (
    <div className={styles.job}>
      <div id="jobContainer" className={styles.jobContainer}>
        {jobData === null ? (
          <div className={styles.loadingContainer}>
            <div className={styles.loadingAnimation}>
              <KSpinner type="big" />
            </div>
            <div className={styles.loadingText}>
              <p>{t('LOADING...')}</p>
            </div>
          </div>
        ) : !jobData.title ? (
          <Page404 />
        ) : jobData.publicApproval === 'Approved' ? (
          <Grid container justify="center">
            <Grid item xs={12} md={8}>
              <div
                className={`${styles.jobStatus} ${
                  isClosed() ? styles.closed : styles.open
                }`}
              >
                {jobData.status}
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <h1 className={styles.jobTitle}>{jobData?.title}</h1>
              {jobData?.salaryPublic && (
                <h3 className={styles.jobSalaryTitle}>
                  {t('Salary Range')}: ${jobData?.salaryLower} - $
                  {jobData?.salaryUpper} {jobData?.salaryCurrency}
                </h3>
              )}

              <Box display="flex" flexDirection="row">
                <PlaceIcon style={{ color: '#4da7b6' }} />
                <h3 className={styles.jobLocation}>
                  {jobData?.location}
                </h3>
              </Box>
              <Box display="flex" flexDirection="row">
                <WatchLaterIcon style={{ color: '#4da7b6' }} />
                <h3 className={styles.jobTime}>{jobData?.jobTime}</h3>
              </Box>
              <Box display="flex" flexDirection="row">
                {jobData?.isJobRemote ? (
                  <>
                    <WifiIcon style={{ color: '#4da7b6' }} />
                    <h3 className={styles.jobType}>{t('Remote')}</h3>
                  </>
                ) : null}
              </Box>
            </Grid>
            <Divider className={styles.jobLineDivider} />
            <Grid item xs={12} md={8}>
              <div
                className={styles.jobDescription}
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(jobData.details),
                }}
              ></div>
            </Grid>
            <Divider className={styles.jobLineDivider} />
            <Grid item xs={12} md={8}>
              <Typography className={styles.jobAboutUsTitle}>
                {t('Why will you love working with us?')}
              </Typography>
              <ul className={styles.jobListInsights}>
                <li>{t('We thrive to reach out to tomorrow.')}</li>
                <li>
                  {t(
                    'We are a world class team who work together across cultures and disciplines.',
                  )}
                </li>
                <li>
                  {t(
                    'Transparency and challenging projects that will let you grow and learn.',
                  )}
                </li>
                <li>
                  {t(
                    'We offer to you trust, sensibility and welfare, because you are important to us.',
                  )}
                </li>
              </ul>
            </Grid>
            <Divider className={styles.jobLineDivider} />
            {vacancySend ? (
              <Grid item xs={12} md={8}>
                <p className={styles.messageSuccess}>
                  {t(
                    'Thanks! We’ll get in touch with you as soon as possible.',
                  )}
                </p>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <CheckCircleOutlineIcon
                    className={styles.checkIcon}
                  />
                  {jobData.status === 'Open' ? (
                    <p className={styles.messageSuccessOpenJob}>
                      {t(
                        'Your information was successfully submited.',
                      )}
                    </p>
                  ) : (
                    <p className={styles.messageSuccessClosedJob}>
                      {t(
                        'Thanks for sharing with us your contact information, we’ll get in touch if the position becomes available!',
                      )}
                    </p>
                  )}
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={8}>
                  <Typography className={styles.jobFormTitle}>
                    {t(
                      'Interested? Leave us your information and we’ll be in touch with you soon!',
                    )}
                  </Typography>
                </Grid>
                <CandidateJobForm
                  history={history}
                  jobId={match.params?.jobId}
                />
              </>
            )}
          </Grid>
        ) : (
          <Page404 />
        )}
      </div>
    </div>
  );
};

export default Job;
