import React, { useState, useEffect } from 'react';
import { addNewJob } from 'store/jobs/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  jobTitleRequiredValidator,
  richTextRequiredValidator,
  salaryRequiredValidator,
  salaryUpperRequiredValidator,
  stringRequiredValidator,
  nameValidator,
  nameWithNumbersValidator,
  numberRequiredValidator,
} from 'utils/validations';

import { sel_create_success } from 'store/jobs/selectors';

import { IUserListing } from 'modules/Users/typings';

import { IOption } from 'components/KMultiSelect/KMultiSelect';
import { KFieldGroup } from 'components/KFormSteps/KFormSteps';
import KBaseContainer from 'components/KBaseContainer';
import KButton from 'components/KButton';
import KFormSteps from 'components/KFormSteps';
import KModalCard from 'components/KModalCard';
import resumeImg from 'img/resume.svg';
import styles from './AddJob.module.scss';
import userService from 'services/users.service';
import { useTranslation } from 'react-i18next';

const validationSchema1 = Yup.object({
  clientJobId: nameWithNumbersValidator,
  clientName: nameWithNumbersValidator,
  departmentId: numberRequiredValidator,
  jobSeniority: stringRequiredValidator,
  jobTime: stringRequiredValidator,
  jobType: stringRequiredValidator,
  jobUrgency: stringRequiredValidator,
  location: nameValidator,
  salaryCurrency: stringRequiredValidator,
  requiredPositions: numberRequiredValidator,
  //salaryGross: stringRequiredValidator,
  salarylower: salaryRequiredValidator,
  salaryPeriod: stringRequiredValidator,
  salaryupper: salaryUpperRequiredValidator,
  title: jobTitleRequiredValidator,
});
const validationSchema2 = Yup.object({
  details: richTextRequiredValidator,
});

const validationSchema3 = Yup.object({
  /* hiringManagers: stringRequiredValidator, */
});

const AddJob = () => {
  const { t } = useTranslation();
  const initialComponents: KFieldGroup[] = [
    {
      name: t('Basics'),
      validationSchema: validationSchema1,
      fields: [
        {
          fieldProps: {
            label: t('Job title'),
            name: 'title',
            required: true,
            options: [
              'Backend Developer',
              'Cybersecurity',
              'Design',
              'Frontend Developer',
              'Fullstack Developer',
              'Java Developer',
              'JavaScript Developer',
              'React Developer',
              'Salesforce',
            ],
          },
          initialValue: '',
          type: 'autocomplete',
          layoutSize: 6,
        },
        /* {
          fieldProps: {
            label: 'Job Seniority',
            name: 'jobSeniority',
            required: true,
            options: [
              { value: 'Sr', label: 'Senior' },
              { value: 'Mid', label: 'Mid' },
              { value: 'Jr', label: 'Junior' },
            ],
          },
          initialValue: 'Jr',
          type: 'select',
          layoutSize: 3,
        }, */

        {
          fieldProps: {
            label: t('Job Department'),
            name: 'departmentId',
            required: true,
            options: [
              { value: 1, label: 'HR' },
              { value: 2, label: 'KSQU' },
              { value: 3, label: 'Marketing' },
              { value: 4, label: 'Operations' },
              { value: 5, label: 'Project Management' },
              { value: 6, label: 'Technology' },
              { value: 7, label: 'UX' },
            ],
          },
          initialValue: 1,
          initialValueSelector: '',
          type: 'department',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: 'Location',
            name: 'location',
            required: true,
            options: [
              'Dallas, Texas, US',
              'Irving, Texas, US',
              'Hyderabad, Andhra Pradesh, IN',
              'Mérida, Yucatán, MX',
              'Mexico City, MX',
              'Santiago, DO',
            ],
          },
          initialValue: 'Dallas, Texas, US',
          type: 'location',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Remote'),
            name: 'isJobRemote',
            option: t('Remote'),
            tab: true,
          },
          initialValue: false,
          type: 'checkbox',
          layoutSize: 2,
        },
        {
          fieldProps: {
            label: t('Job type'),
            name: 'jobType',
            required: true,
            options: [
              { value: 'Permanent', label: t('Permanent') },
              { value: 'Temporal', label: t('Temporal') },
            ],
          },
          initialValue: 'Permanent',
          type: 'select',
          layoutSize: 2,
        },
        {
          fieldProps: {
            label: t('Job Urgency'),
            name: 'jobUrgency',
            required: true,
            options: [
              { value: 'High', label: t('High') },
              { value: 'Mid', label: t('Mid') },
              { value: 'Low', label: t('Low') },
            ],
          },
          initialValue: 'Low',
          type: 'select',
          layoutSize: 2,
        },

        {
          fieldProps: {
            label: t('Job time'),
            name: 'jobTime',
            required: true,
            options: [
              { value: 'Full-time', label: t('Full-time') },
              { value: 'Part-time', label: t('Part-time') },
            ],
          },
          initialValue: 'Full-time',
          type: 'select',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Job Status'),
            name: 'status',
            required: true,
            options: [
              { value: 'Open', label: t('Open') },
              { value: 'Closed', label: t('Closed') },
            ],
          },
          initialValue: 'Open',
          type: 'select',
          layoutSize: 3,
        },
        {
          fieldProps: {
            label: t('Required Positions'),
            name: 'requiredPositions',
            required: true,
          },
          initialValue: '',
          type: 'text',
          layoutSize: 3,
        },
        {
          fieldProps: {
            label: t('Salary currency'),
            name: 'salaryCurrency',
            required: true,
            options: [
              { value: 'DOP', label: 'DOP' },
              { value: 'INR', label: 'INR' },
              { value: 'MXN', label: 'MXN' },
              { value: 'USD', label: 'USD' },
            ],
          },
          initialValue: 'USD',
          type: 'select',
          layoutSize: 2,
        },
        {
          fieldProps: {
            label: t('Salary range'),
            name: 'salary',
            required: true,
          },
          initialValue: '',
          type: 'range',
          layoutSize: 4,
        },
        // {
        //   fieldProps: {
        //     label: 'Salary Type',
        //     name: 'salaryGross',
        //     required: true,
        //     options: [
        //       { value: 'Gross', label: 'Gross' },
        //       { value: 'Net', label: 'Net' },
        //     ],
        //   },
        //   initialValue: 'Gross',
        //   type: 'select',
        //   layoutSize: 2,
        // },
        {
          fieldProps: {
            label: t('Salary period'),
            name: 'salaryPeriod',
            required: true,
            options: [
              { value: 'year', label: t('Annually') },
              { value: 'month', label: t('Monthly') },
              { value: 'hour', label: t('Hourly') },
            ],
          },
          initialValue: 'year',
          type: 'select',
          layoutSize: 3,
        },
        {
          fieldProps: {
            label: t('Public'),
            name: 'salaryPublic',
            option: t('Public salary'),
            tab: true,
          },
          initialValue: false,
          type: 'checkbox',
          layoutSize: 3,
        },
        {
          fieldProps: {
            label: t('Client'),
            name: 'clientName',
          },
          initialValue: '',
          type: 'text',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Client Job ID'),
            name: 'clientJobId',
          },
          initialValue: '',
          type: 'text',
          layoutSize: 6,
        },
      ],
    },
    {
      name: t('Description'),
      validationSchema: validationSchema2,
      fields: [
        {
          fieldProps: { label: t('Description'), name: 'details' },
          initialValue: '',
          type: 'textRich',
          layoutSize: 12,
        },
      ],
    },
    {
      name: t('Hiring Team'),
      validationSchema: validationSchema3,
      fields: [
        {
          fieldProps: {
            label: t('External'),
            name: 'externalManager',
            option: t('External manager'),
            tab: true,
            options: [],
          },
          initialValue: false,
          initialValueSelector: [],
          type: 'externalManager',
          layoutSize: 12,
        },
      ],
    },
  ];
  const history = useHistory();
  const dispatch = useDispatch();
  const currentJob = useSelector(sel_create_success);
  const [open, setOpen] = useState<boolean>(false);
  const [components, setComponents] = useState<KFieldGroup[]>([
    ...initialComponents,
  ]);
  const [users, setUsers] = useState<IUserListing[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      await userService.getAll().then((res) => setUsers(res));
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      const interviewersOptions = users
        .filter((user) =>
          ['MANAGER', 'ADMINISTRATOR'].includes(user.role.level),
        )
        .map((interviewer) => ({
          id: interviewer.id,
          label: `${interviewer.firstName} ${interviewer.lastName}`,
        }));
      const updatedComponents = components;
      updatedComponents[2].fields[0].fieldProps.options = interviewersOptions;
      setComponents(updatedComponents);
    }
    //eslint-disable-next-line
  }, [users]);

  const handleSubmit = async (values: any) => {
    const {
      clientJobId,
      clientName,
      departmentId,
      details,
      externalManager,
      hiringManagers,
      isJobRemote,
      jobSeniority,
      jobTime,
      jobType,
      jobUrgency,
      location,
      salaryCurrency,
      // salaryGross,
      salarylower,
      salaryPeriod,
      salaryPublic,
      salaryupper,
      status,
      title,
      requiredPositions,
    } = values;
    const response = {
      clientJobId,
      clientName,
      departmentId,
      details,
      externalManager,
      isJobRemote,
      jobSeniority,
      jobTime,
      jobType,
      jobUrgency,
      requiredPositions,
      location,
      salaryCurrency,
      salaryGross: 'Gross',
      salaryPeriod,
      salaryPublic,
      status,
      publicApproval: 'Not Approved',
      title,
      hiringManagers: externalManager
        ? []
        : hiringManagers.map((value: IOption) => value.id),
      salaryLower: !salarylower.includes(',')
        ? salarylower.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : salarylower,
      salaryUpper: !salaryupper.includes(',')
        ? salaryupper.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : salaryupper,
    };

    await dispatch(addNewJob(response, setOpen));
  };

  const content = (
    <div className={styles.modal}>
      <h2>{t('Job Created')}</h2>
      <img src={resumeImg} alt="Job created" />
      <p>
        {t('You successfully created')} {currentJob.title}{' '}
      </p>
      <div className={styles.buttons}>
        <KButton
          className={styles.button}
          variant="outlined"
          onClick={() => {
            history.push('/jobs');
          }}
        >
          {t('DONE')}
        </KButton>
        <KButton
          className={styles.button}
          onClick={() => {
            history.push(`/jobs/${currentJob.id}`);
          }}
        >
          {t('VIEW JOB')}
        </KButton>
      </div>
    </div>
  );

  return (
    <KBaseContainer>
      <KModalCard open={open}>{content}</KModalCard>
      <KFormSteps
        name={t('Create Job')}
        fieldsGroups={components}
        onSuccess={handleSubmit}
      />
    </KBaseContainer>
  );
};

export default AddJob;
