import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Page404.module.scss';

const Page404: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.page404}>
      <div className={styles.container}>
        <div className={styles.status}>404</div>
        <p className={styles.message}>
          {t(
            'Page not found! But don’t worry, we still have interesting offers for you',
          )}
          .
        </p>
      </div>
    </div>
  );
};

export default Page404;
