import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';

interface KSpinnerProps extends CircularProgressProps {
  type?: 'bigThick' | 'smallWhite' | 'big';
}

const KSpinner: React.FC<KSpinnerProps> = ({ type, ...props }) => {
  const typePropsSwitch = (): CircularProgressProps => {
    switch (type) {
      case 'bigThick':
        return {
          size: 40,
          thickness: 8,
        };
      case 'smallWhite':
        return {
          size: '1rem',
          style: { color: 'white' },
        };
      case 'big':
        return {
          size: 60,
          thickness: 8,
        };
      default:
        return {};
    }
  };
  const typeProps = typePropsSwitch();
  return <CircularProgress {...typeProps} {...props} />;
};

export default KSpinner;
