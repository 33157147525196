import { IAuth } from 'modules/Auth/typings';

export const sessionData = {
  getUser: (): IAuth | null => {
    try {
      const user = localStorage.getItem('user');
      if (!user) {
        throw new Error('no user data');
      }
      const parsedUser = JSON.parse(user);
      return parsedUser;
    } catch (e) {
      return null;
    }
  },
  setUser: (user: IAuth) => {
    try {
      localStorage.setItem('user', JSON.stringify(user));
    } catch (e) {}
  },
};
