import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import {
  KBaseContainer,
  KButton,
  KCheckbox,
  KModalCard,
  KTableApi,
} from 'components';

import { ICandidateListing } from 'modules/Candidates/typings';
import {
  deleteCandidate,
  getCandidates,
} from 'store/candidates/thunks';
import {
  sel_candidatesData,
  sel_candidatesLoading,
  sel_candidatesCount,
  sel_candidatesStats,
} from 'store/candidates/selectors';
import { format } from 'date-fns';
import Filters from 'modules/Candidates/Components/Filters';
import styles from './CandidateListing.module.scss';
import { getRole } from 'utils/helpers';
import useDebounce from 'hooks/use-debounce';
import { IUser, IJob } from 'modules/Candidates/typings';
import { useTranslation } from 'react-i18next';
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  options?: string;
  sortable?: boolean;
  isChip?: boolean;
}

interface IFilter {
  label: string;
  name: string;
  total: number;
}

interface ICandidateStateListing {
  id: number;
  firstName: string;
  lastName: string;
  website: string;
  recruiter: string;
  interviewers: IUser[];
  interviewer: string;
  createdAt: Date;
  jobs: IJob[];
  stage: string;
  recruiterId: string;
}

const useQuery = (): Record<any, any> => {
  return new URLSearchParams(useLocation().search);
};

const CandidateListing: FC = () => {
  const { t } = useTranslation();
  const headCells: HeadCell[] = [
    {
      disablePadding: true,
      id: 'name',
      label: t('Candidate'),
      numeric: false,
    },
    {
      disablePadding: false,
      id: 'position',
      label: t('Position'),
      numeric: false,
    },
    {
      disablePadding: false,
      id: 'recruiter',
      label: t('Recruiter'),
      numeric: false,
    },
    {
      disablePadding: false,
      id: 'createdAt',
      label: t('Created'),
      numeric: false,
    },
    {
      disablePadding: false,
      id: 'stage',
      label: t('Stage'),
      numeric: false,
      isChip: true,
    },
    {
      disablePadding: false,
      id: 'options',
      label: '',
      numeric: false,
      sortable: false,
    },
  ];
  const query = useQuery();
  const defaultFilter = query.get('defaultFilter');
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [shownCandidates, setShownCandidates] = useState<
    ICandidateStateListing[]
  >([]);
  const [filter, setFilter] = useState<string>(
    defaultFilter || 'all',
  );
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState('desc');
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const [showMyCandidates, setShowMyCandidates] = useState(false);

  const debouncedSearchTerm = useDebounce(search, 500);

  const dispatch = useDispatch();
  const candidates: ICandidateListing[] = useSelector(
    sel_candidatesData,
  );
  const loading = useSelector(sel_candidatesLoading);
  const stats = useSelector(sel_candidatesStats);
  const count = useSelector(sel_candidatesCount);

  const candidateFilters: IFilter[] = [
    {
      label: t('ALL'),
      name: 'all',
      total: stats.all,
    },
    {
      label: t('NEW'),
      name: 'PROSPECTIVE',
      total: stats.prospective,
    },
    {
      label: t('ACTIVE'),
      name: 'ACTIVE',
      total: stats.active,
    },
    {
      label: t('HIRED'),
      name: 'HIRED',
      total: stats.hired,
    },
    {
      label: t('REJECTED'),
      name: 'REJECTED',
      total: stats.rejected,
    },
  ];

  const getCandidatesFn = () => {
    const orderByFilter = orderBy;
    const orderFilter = order;

    dispatch(
      getCandidates(
        filter,
        orderFilter,
        orderByFilter,
        rowsPerPage,
        page,
        search,
        showMyCandidates,
      ),
    );
  };

  useEffect(() => {
    setPage(0);
  }, [search, filter, showMyCandidates]);

  useEffect(() => {
    const orderByFilter = orderBy;
    const orderFilter = order;
    dispatch(
      getCandidates(
        filter,
        orderFilter,
        orderByFilter,
        rowsPerPage,
        page,
        debouncedSearchTerm,
        showMyCandidates,
      ),
    );
  }, [
    debouncedSearchTerm,
    filter,
    orderBy,
    rowsPerPage,
    page,
    order,
    dispatch,
    showMyCandidates,
  ]);

  useEffect(() => {
    const candidatesTable = candidates.map((candidate) => {
      return {
        ...candidate,
        recruiterId: candidate.recruiter?.id || t('Not assigned'),
        id: candidate.id,
        name: `${candidate.firstName} ${candidate.lastName}`,
        position: candidate.jobs[0] ? candidate.jobs[0].title : '',
        recruiter: candidate.recruiter
          ? `${candidate.recruiter.firstName} ${candidate.recruiter.lastName}`
          : t('Not assigned'),
        interviewer: candidate.interviewer
          ? `${candidate.interviewer.firstName} ${candidate.interviewer.lastName}`
          : t('Not assigned'),
        created: format(new Date(candidate.createdAt), 'dd/MM/yy'),
        stage: candidate.stage.name,
      };
    });
    setShownCandidates(candidatesTable);
  }, [candidates, t]);

  const handleDelete = async () => {
    if (!currentId) return;
    await dispatch(deleteCandidate(currentId));
    getCandidatesFn();
    setOpen(false);
  };

  const handleOpen = (id: string) => {
    setCurrentId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentId(null);
    setOpen(false);
  };

  const handleSearch = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearch(event.target.value);
  };

  return (
    <KBaseContainer whole>
      <KModalCard
        onClose={handleClose}
        open={open}
        type={'confirmation'}
        confirmFn={handleDelete}
        confirmMsg={t(
          'You will not be able to recover this candidate!',
        )}
        confirmBtn={t('Delete')}
        setOpenModal={setOpen}
      />

      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Grid container>
          <Grid item xs={6} md={2}>
            <Typography variant="h1">{t('Candidates')}</Typography>
          </Grid>

          <Grid
            container
            alignItems="flex-end"
            spacing={2}
            item
            xs={12}
            md={8}
            className={styles.searchbar}
          >
            <Grid item>
              <SearchIcon />
            </Grid>
            <Grid item>
              <TextField
                className={styles.searchCandidate}
                label={t('Search for a candidate...')}
                onChange={handleSearch}
                value={search}
              />
            </Grid>
            <Grid item>
              <KCheckbox
                onClick={() => {
                  setShowMyCandidates((state) => !state);
                }}
                tab={false}
                value={showMyCandidates}
              />
            </Grid>
            <Grid item>
              <div className={styles.checkboxText}>
                {t('My Candidates')}
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={6}
            md={2}
            className={styles.addButtonContainer}
          >
            {['ADMINISTRATOR', 'RECRUITER'].includes(getRole()) && (
              <Link to="/candidates/create">
                <KButton>{t('Add Candidate')}</KButton>
              </Link>
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} md={2}>
          <Filters
            activeFilter={filter}
            changeFilter={(filterName) => setFilter(filterName)}
            filters={candidateFilters}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <div className={styles.tableContainer}>
            <KTableApi
              data={shownCandidates}
              headCells={headCells}
              options={
                ['ADMINISTRATOR', 'RECRUITER'].includes(getRole())
                  ? {
                      view: '/candidates/',
                      delete: (id: string) => {
                        handleOpen(id);
                      },
                      edit: '/candidates/edit/',
                    }
                  : { view: '/candidates/' }
              }
              isLoading={loading}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              order={order}
              setOrder={setOrder}
              count={count}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
      </Grid>
    </KBaseContainer>
  );
};
export default CandidateListing;
