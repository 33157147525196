import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';

const notifications = createSlice({
  name: 'snackbar',
  initialState: {
    snackbarOpen: false,
    snackbarType: undefined,
    snackbarMessage: '',
  },
  reducers,
});
export const { setSnackbar } = notifications.actions;
export default notifications.reducer;
