import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCandidate } from 'store/candidates/thunks';
import { sel_candidateData } from 'store/candidates/selectors';
import { KFieldGroup } from 'components/KFormSteps/KFormSteps';
import { IOption } from 'components/KMultiSelect/KMultiSelect';
import KBaseContainer from 'components/KBaseContainer';
import KFormSteps from 'components/KFormSteps';
import KSpinner from 'components/KSpinner';
import KModalCard from 'components/KModalCard';
import KButton from 'components/KButton';
import jobService from 'services/jobs.service';
import userService from 'services/users.service';
import { IJob } from 'modules/Jobs/typings';
import * as Yup from 'yup';
import {
  emailValidator,
  nameValidator,
  referralValidator,
  phoneRequiredValidator,
  stringRequiredValidator,
  urlValidator,
  salaryValidator,
} from 'utils/validations';
import candidateImg from 'img/hire.svg';
import styles from './AddCandidate.module.scss';
import { IUserListing } from 'modules/Users/typings';
import { useTranslation } from 'react-i18next';

const validationSchema1 = Yup.object({
  email: emailValidator,
  firstName: nameValidator,
  lastName: nameValidator,
  phone: phoneRequiredValidator,
  referral: referralValidator,
  source: nameValidator,
  website: urlValidator,
  linkedin: urlValidator,
  salaryOffer: salaryValidator,
  jobId: stringRequiredValidator,
});
const validationSchema2 = Yup.object({});

const validationSchema3 = Yup.object({
  recruiterId: stringRequiredValidator,
});

const AddCandidate = () => {
  const { t } = useTranslation();
  const initialComponents: KFieldGroup[] = [
    {
      name: t('Basics'),
      validationSchema: validationSchema1,
      fields: [
        {
          fieldProps: {
            label: t('First Name'),
            name: 'firstName',
            required: true,
          },
          initialValue: '',
          type: 'text',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Last Name'),
            name: 'lastName',
            required: true,
          },
          initialValue: '',
          type: 'text',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Phone'),
            name: 'phone',
            required: true,
          },
          initialValue: '',
          type: 'text',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Email'),
            name: 'email',
            required: true,
          },
          initialValue: '',
          type: 'text',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Source'),
            name: 'source',
            required: true,
          },
          initialValue: '',
          type: 'text',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Referral'),
            name: 'referral',
          },
          initialValue: '',
          type: 'text',
          layoutSize: 6,
        },

        {
          fieldProps: {
            label: t('Position'),
            options: [],
            name: 'jobId',
            required: true,
          },
          initialValue: [],
          type: 'position',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Country'),
            name: 'country',
            required: false,
          },
          initialValue: '',
          type: 'candidateLocation',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Stage'),
            name: 'stageId',
            options: [
              { value: 1, label: t('NEW') },
              { value: 2, label: t('ACTIVE') },
              { value: 3, label: t('HIRED') },
              { value: 4, label: t('REJECTED') },
            ],
          },
          initialValue: 1,
          initialValueSalaryOffer: '',
          type: 'stage',
          layoutSize: 12,
        },
        {
          fieldProps: { label: t('Website'), name: 'website' },
          initialValue: '',
          type: 'text',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('LinkedIn Profile'),
            name: 'linkedin',
          },
          initialValue: '',
          type: 'text',
          layoutSize: 6,
        },
      ],
    },
    {
      name: t('Resume'),
      validationSchema: validationSchema2,
      fields: [
        {
          fieldProps: { label: t('Resume'), name: 'resume' },
          initialValue: [],
          type: 'dropzone',
          layoutSize: 12,
        },
      ],
    },
    {
      name: t('Assignments'),
      validationSchema: validationSchema3,
      fields: [
        {
          fieldProps: {
            label: t('Recruiter'),
            options: [],
            name: 'recruiterId',
          },
          initialValue: '',
          type: 'select',
          layoutSize: 12,
        },
        {
          fieldProps: {
            label: t('First Contact Interview'),
            name: 'firstContact',
            options: [],
          },
          initialValue: [],
          type: 'multiSelect',
          layoutSize: 12,
        },
        {
          fieldProps: {
            label: t('Technical Interview 1'),
            name: 'techInterview1',
            options: [],
          },
          initialValue: [],
          type: 'multiSelect',
          layoutSize: 12,
        },
        {
          fieldProps: {
            label: t('Technical Interview 2'),
            name: 'techInterview2',
            options: [],
          },
          initialValue: [],
          type: 'multiSelect',
          layoutSize: 12,
        },
      ],
    },
  ];
  const history = useHistory();
  const candidate = useSelector(sel_candidateData);
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [users, setUsers] = useState<IUserListing[]>([]);
  const [components, setComponents] = useState<KFieldGroup[]>([
    ...initialComponents,
  ]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      await jobService.getAllOpen().then((res) => setJobs(res));
      await userService.getAll().then((res) => setUsers(res));
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      const interviewersOptions = users.map((interviewer) => ({
        id: interviewer.id,
        label: `${interviewer.firstName} ${interviewer.lastName}`,
      }));

      const recruiterOptions = users
        .filter((user) => user.role.level === 'RECRUITER')
        .map((recruiter) => {
          return {
            value: recruiter.id,
            label: `${recruiter.firstName} ${recruiter.lastName}`,
          };
        });

      const updatedComponents = components;
      updatedComponents[2].fields[0].fieldProps.options = recruiterOptions;
      updatedComponents[2].fields[1].fieldProps.options = interviewersOptions;
      updatedComponents[2].fields[2].fieldProps.options = interviewersOptions;
      updatedComponents[2].fields[3].fieldProps.options = interviewersOptions;

      setComponents(updatedComponents);
    }
    //eslint-disable-next-line
  }, [users]);

  useEffect(() => {
    if (jobs.length) {
      const updatedComponents = [...initialComponents];
      const jobsOptions = jobs.map((job) => {
        const id = job.jobId ? job.jobId : job.id;
        return {
          value: job.id,
          name: `${job.title} - (${id})`,
        };
      });

      updatedComponents[0].fields[6].fieldProps.options = jobsOptions;
      /* updatedComponents[0].fields[8].initialValue =
        jobsOptions[0].value; */
      setComponents(updatedComponents);
    }
    //eslint-disable-next-line
  }, [jobs]);

  const handleSubmit = async (values: any) => {
    const {
      firstContact,
      techInterview1,
      techInterview2,
      country,
      state,
      city,
    } = values;

    const firstContactIds = firstContact.map(
      (value: IOption) => value.id,
    );
    const techInterview1Ids = techInterview1.map(
      (value: IOption) => value.id,
    );
    const techInterview2Ids = techInterview2.map(
      (value: IOption) => value.id,
    );

    const candidate = {
      ...values,
      jobId: values.jobId.value,
      firstContact: firstContactIds,
      techInterview1: techInterview1Ids,
      techInterview2: techInterview2Ids,
      country: country ? JSON.stringify(country) : '',
      state: state ? JSON.stringify(state) : '',
      city: city ? JSON.stringify(city) : '',
    };

    await dispatch(addNewCandidate(candidate, setOpen));
  };

  return (
    <KBaseContainer>
      <KModalCard open={open}>
        <div className={styles.modal}>
          <h2>{t('Candidate Created')}</h2>
          <img src={candidateImg} alt="Candidate created" />
          <p>{t('Your candidate has been successfully created')}</p>
          <div className={styles.buttons}>
            <KButton
              className={styles.button}
              variant="outlined"
              onClick={() => {
                history.push('/candidates');
              }}
            >
              {t('DONE')}
            </KButton>
            <KButton
              className={styles.button}
              onClick={() => {
                history.push(`/candidates/${candidate.id}`);
              }}
            >
              {t('VIEW CANDIDATE')}
            </KButton>
          </div>
        </div>
      </KModalCard>
      {components.length !== 0 && users.length !== 0 ? (
        <KFormSteps
          name={t('Create Candidate')}
          fieldsGroups={components}
          onSuccess={handleSubmit}
        />
      ) : (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingAnimation}>
            <KSpinner type="bigThick" />
          </div>
          <div className={styles.loadingText}>
            <p>{t('LOADING CANDIDATE...')}</p>
          </div>
        </div>
      )}
    </KBaseContainer>
  );
};

export default AddCandidate;
