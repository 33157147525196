import React, { FC, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Location from 'services/location.service';
import { useField } from 'formik';
import { KSelectAutocomplete } from 'components';
import { ICity, ICountry, IState } from 'services/typings';
import { useTranslation } from 'react-i18next';

interface ICandidateLocationProps {
  origin: string;
  label: string;
  name: string;
  error?: any;
  helperTextCountry?: string;
  helperTextState?: string;
  helperTextCity?: string;
}

const KCandidateLocation: FC<ICandidateLocationProps> = ({
  origin,
  error,
  helperTextCountry,
  helperTextState,
  helperTextCity,
}) => {
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  const [country, , countryHelpers] = useField('country');
  const [state, , stateHelpers] = useField('state');
  const [, , cityHelpers] = useField('city');

  useEffect(() => {
    (async function () {
      try {
        const countries = await Location.getCountries();
        setCountries(countries);
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(country.value).length > 0) {
      (async function () {
        try {
          const states = await Location.getStates(
            (country.value as any).id,
          );
          setStates(states);
        } catch (error) {}
      })();
    }
    // eslint-disable-next-line
  }, [country.value]);

  useEffect(() => {
    if (Object.keys(state.value).length > 0) {
      (async function () {
        try {
          const cities = await Location.getCities(
            (state.value as any).id,
          );
          setCities(cities);
        } catch (error) {}
      })();
    }
    // eslint-disable-next-line
  }, [state.value, country.value]);
  const { t } = useTranslation();

  return (
    <Grid container spacing={origin === 'vacancy' ? 2 : 4}>
      <Grid item md={origin === 'vacancy' ? 6 : 4} xs={12}>
        <KSelectAutocomplete
          label={t('Country')}
          name="country"
          options={countries}
          noOptionsText="Loading ..."
          onChangeOpt={(_: any, value: any) => {
            countryHelpers.setValue(value);
            stateHelpers.setValue('');
            cityHelpers.setValue('');
            setStates([]);
            setCities([]);
          }}
          origin={origin}
          required={origin === 'vacancy' ? true : false}
          error={error}
          helperText={helperTextCountry}
        />
      </Grid>
      <Grid item md={origin === 'vacancy' ? 6 : 4} xs={12}>
        <KSelectAutocomplete
          label={t('State')}
          name="state"
          // required={Boolean(country.value)}
          options={states}
          noOptionsText="Loading ..."
          disabled={!Boolean(country.value)}
          onChangeOpt={(_: any, value: any) => {
            stateHelpers.setValue(value);
            cityHelpers.setValue('');
            setCities([]);
          }}
          origin={origin}
          required={origin === 'vacancy' ? true : false}
          error={error}
          helperText={helperTextState}
        />
      </Grid>
      <Grid item md={origin === 'vacancy' ? 6 : 4} xs={12}>
        <KSelectAutocomplete
          label={t('City')}
          name="city"
          // required={Boolean(state.value)}
          options={cities}
          noOptionsText="Loading ..."
          disabled={!Boolean(state.value)}
          origin={origin}
          required={origin === 'vacancy' ? true : false}
          error={error}
          helperText={helperTextCity}
        />
      </Grid>
    </Grid>
  );
};

export default KCandidateLocation;
