import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { AuthModule, VacancyModule } from 'modules';
import PrivacyPolicy from 'views/PrivacyPolicy';
import { KLayout, KPrivateRoute } from 'components';

const RootRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/vacancy" component={VacancyModule} />
        <Route path="/auth" component={AuthModule} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <KPrivateRoute path="/">
          <KLayout />
        </KPrivateRoute>
      </Switch>
    </Router>
  );
};

export default RootRouter;
