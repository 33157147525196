import React, { useState, FC } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Avatar,
} from '@material-ui/core';
import KBaseContainer from 'components/KBaseContainer';
import KSelect from 'components/KSelect';
import { sessionData } from 'utils/localStorage';
import profileService from 'services/profiles.service';
import { useTranslation } from 'react-i18next';
import styles from './SettingsModule.module.scss';
import { withStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selectorProfilePicture } from 'store/auth/selectors';
import changeImage from 'img/changeImage.png';
import _ from 'lodash';
import KButton from 'components/KButton';
import { useDispatch } from 'react-redux';
import { editUser } from 'store/users/thunks';
import VersionModule from './../../../Version';

const ValidationTextFieldFilled = withStyles({
  root: {
    '& input': {
      background: '#f0efef',
      fontSize: 20,
      color: '#bdbdbd',
    },
    '& label': {
      color: '#a6a6a6',
      backgroundColor: '#fff',
      padding: 4,
    },
    '& label.Mui-focused': {
      color: '#a6a6a6',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#a6a6a6',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#a6a6a6',
        borderWidth: '1px',
      },
    },
  },
})(TextField);

const ValidationTextField = withStyles({
  root: {
    '& input': {
      background: '#ffff',
      fontSize: 20,
    },
    '& label': {
      backgroundColor: '#fff',
      padding: 4,
    },
  },
})(TextField);

const SettingsModule: FC = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(1);
  const { t, i18n } = useTranslation();
  const roles = [
    { value: '1', label: t('Administrator') },
    { value: '2', label: t('Recruiter') },
    { value: '4', label: t('Manager') },
    { value: '3', label: t('Interviewer') },
  ];
  const avatar = useSelector(selectorProfilePicture);
  const returnFormObject = () => {
    const data = sessionData.getUser();
    if (data?.user) {
      return {
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        email: data.user.email,
        role: data.user.roleId,
        password: '***********',
        locale: data.user.locale,
      };
    } else {
      return {
        firstName: '',
        lastName: '',
        email: '',
        role: -1,
        password: '***********',
        locale: 'en',
      };
    }
  };
  const [formDataOriginal, setFormDataOriginal] = useState(
    returnFormObject(),
  );
  const [formData, setFormData] = useState(returnFormObject());
  const isSameFormData = _.isEqual(formDataOriginal, formData);
  const updateOriginalForm = async () => {
    if (!isSameFormData) {
      try {
        const session = sessionData.getUser();
        if (!session) {
          throw new Error('There is no session');
        }
        if (formDataOriginal.locale !== formData.locale) {
          await profileService.editOne(
            sessionData.getUser()?.user.id || '',
            { locale: formData.locale },
          );
          sessionData.setUser({
            ...session,
            user: {
              ...session?.user,
              locale: formData.locale,
            },
          });
          setFormDataOriginal(formData);
          i18n.changeLanguage(formData.locale);
        }
        if (
          formDataOriginal.firstName !== formData.firstName ||
          formData.lastName !== formDataOriginal.lastName
        ) {
          dispatch(
            editUser(
              session.user.id,
              {
                id: session.user.id,
                email: session.user.email,
                roleId: session.user.roleId,
                firstName: formData.firstName,
                lastName: formData.lastName,
              },
              () => {
                sessionData.setUser({
                  ...session,
                  user: {
                    ...session?.user,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                  },
                });
              },
            ),
          );
        }
      } catch (e) {}
    }
  };
  return (
    <KBaseContainer whole>
      <Typography variant="h1" className={styles.settings}>
        {t('Settings')}
      </Typography>
      <Grid className={styles.mainBox}>
        <Grid className={styles.optionsBox}>
          {['Profile', 'Version'].map((option, index) => {
            const selected = index === selectedOption;
            return (
              <div
                key={option}
                className={
                  index === selectedOption
                    ? styles.optionSelected
                    : styles.option
                }
                onClick={
                  index !== selectedOption
                    ? () => setSelectedOption(index)
                    : () => null
                }
              >
                <span
                  style={{ color: selected ? '#227c8b' : '#373737' }}
                >
                  {t(option)}
                </span>
                <span className={styles.iconWrapper}>
                  {selected ? <ChevronRight /> : null}
                </span>
              </div>
            );
          })}
        </Grid>
        <Grid container direction="column">
          {selectedOption === 0 && (
            <>
              <div className={styles.personalInformation}>
                {t('Personal Information')}
              </div>
              <div className={styles.imageWrapper}>
                <Avatar
                  src={avatar}
                  alt="avatar"
                  className={styles.avatar}
                >
                  {`${formData.firstName && formData.firstName[0]}${
                    formData.lastName && formData.lastName[0]
                  }`}
                </Avatar>
                <img
                  alt={'Change profile'}
                  className={styles.changeImage}
                  src={changeImage}
                />
              </div>
              <Grid container direction="row">
                <ValidationTextField
                  label={t('First Name')}
                  name="lastName"
                  value={formData.firstName}
                  variant="outlined"
                  className={styles.textFieldOutlined}
                  onChange={(event) => {
                    const { value } = event.target;
                    setFormData((state) => {
                      return {
                        ...state,
                        firstName: value,
                      };
                    });
                  }}
                />
                <ValidationTextField
                  label={t('Last Name')}
                  name="lastName"
                  value={formData.lastName}
                  variant="outlined"
                  className={styles.textFieldOutlined}
                  onChange={(event) => {
                    const { value } = event.target;
                    setFormData((state) => {
                      return {
                        ...state,
                        lastName: value,
                      };
                    });
                  }}
                />
              </Grid>
              <Grid container direction="row">
                <ValidationTextFieldFilled
                  label={t('Email')}
                  name="email"
                  value={formData.email}
                  variant="outlined"
                  className={styles.textFieldOutlined}
                  disabled
                />
                <ValidationTextFieldFilled
                  label={t('Role')}
                  name="role"
                  value={
                    roles.find(
                      (role) => role.value === String(formData.role),
                    )?.label || ''
                  }
                  variant="outlined"
                  className={styles.textFieldOutlined}
                  disabled
                />
              </Grid>
              <Grid container direction="row">
                <ValidationTextFieldFilled
                  label={t('Password')}
                  name="passsword"
                  value="***********"
                  variant="outlined"
                  className={styles.textFieldOutlined}
                  disabled
                />
                <KSelect
                  label={t('Language')}
                  variant="outlined"
                  name={'language'}
                  value={formData.locale}
                  className={styles.textFieldOutlined}
                  InputProps={{
                    classes: {
                      input: styles.inputSelect,
                    },
                  }}
                  onChange={(
                    event: React.ChangeEvent<HTMLTextAreaElement>,
                  ) => {
                    setFormData((state) => {
                      return {
                        ...state,
                        locale: event.target.value,
                      };
                    });
                  }}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  options={[
                    {
                      label: 'Español',
                      value: 'es',
                    },
                    {
                      label: 'English',
                      value: 'en',
                    },
                  ]}
                />
              </Grid>
              <div className={styles.saveButtonWrapper}>
                <KButton
                  onClick={updateOriginalForm}
                  disabled={isSameFormData}
                  className={
                    isSameFormData
                      ? styles.saveButton
                      : styles.saveButtonActive
                  }
                >
                  {t('Save')}
                </KButton>
              </div>
            </>
          )}
          {
          selectedOption === 1 && (
            <>
              <Grid container direction="row">
                <Grid container direction="row">
                  <VersionModule />
                </Grid>
              </Grid>
            </>
          )
          /* <div className={styles.personalInformation}>
            {t('Personal Information')}
          </div>
          <div className={styles.imageWrapper}>
            <Avatar
              src={avatar}
              alt="avatar"
              className={styles.avatar}
            >
              {`${formData.firstName && formData.firstName[0]}${
                formData.lastName && formData.lastName[0]
              }`}
            </Avatar>
            <img
              alt={'Change profile'}
              className={styles.changeImage}
              src={changeImage}
            />
          </div>
          <Grid container direction="row">
            <ValidationTextField
              label={t('First Name')}
              name="lastName"
              value={formData.firstName}
              variant="outlined"
              className={styles.textFieldOutlined}
              onChange={(event) => {
                const { value } = event.target;
                setFormData((state) => {
                  return {
                    ...state,
                    firstName: value,
                  };
                });
              }}
            />
            <ValidationTextField
              label={t('Last Name')}
              name="lastName"
              value={formData.lastName}
              variant="outlined"
              className={styles.textFieldOutlined}
              onChange={(event) => {
                const { value } = event.target;
                setFormData((state) => {
                  return {
                    ...state,
                    lastName: value,
                  };
                });
              }}
            />
          </Grid>
          <Grid container direction="row">
            <ValidationTextFieldFilled
              label={t('Email')}
              name="email"
              value={formData.email}
              variant="outlined"
              className={styles.textFieldOutlined}
              disabled
            />
            <ValidationTextFieldFilled
              label={t('Role')}
              name="role"
              value={
                roles.find(
                  (role) => role.value === String(formData.role),
                )?.label || ''
              }
              variant="outlined"
              className={styles.textFieldOutlined}
              disabled
            />
          </Grid>
          <Grid container direction="row">
            <ValidationTextFieldFilled
              label={t('Password')}
              name="passsword"
              value="***********"
              variant="outlined"
              className={styles.textFieldOutlined}
              disabled
            />
            <KSelect
              label={t('Language')}
              variant="outlined"
              name={'language'}
              value={formData.locale}
              className={styles.textFieldOutlined}
              InputProps={{
                classes: {
                  input: styles.inputSelect,
                },
              }}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement>,
              ) => {
                setFormData((state) => {
                  return {
                    ...state,
                    locale: event.target.value,
                  };
                });
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                },
              }}
              options={[
                {
                  label: 'Español',
                  value: 'es',
                },
                {
                  label: 'English',
                  value: 'en',
                },
              ]}
            />
          </Grid>
          <div className={styles.saveButtonWrapper}>
            <KButton
              onClick={updateOriginalForm}
              disabled={isSameFormData}
              className={
                isSameFormData
                  ? styles.saveButton
                  : styles.saveButtonActive
              }
            >
              {t('Save')}
            </KButton>
          </div> */}
        </Grid>
      </Grid>
    </KBaseContainer>
  );
};

export default SettingsModule;
