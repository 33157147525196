import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Grid,
  TextField,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withFormik, FormikBag, FormikProps } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  emailValidator,
  nameValidator,
  phoneRequiredValidator,
  urlValidator,
  countryRequiredValidator,
  stateRequiredValidator,
  cityRequiredValidator,
} from 'utils/validations';

import store from 'store';
import { errorApplicant } from 'store/candidates/candidatesSlice';
import { isError } from 'utils/helpers';
import { sel_applicant_error } from 'store/candidates/selectors';
import { setIsSended } from 'store/vacancy/vacancySlice';
import { useSelector } from 'react-redux';
import candidatesService from 'services/candidates.service';

import KAlert from 'components/KAlert';
import KButton from 'components/KButton';
import KCandidateLocation from 'components/KCandidateLocation';
import KCheckbox from 'components/KCheckbox';
import KFile from 'components/KFile';
import KSpinner from 'components/KSpinner';
import styles from './CandidateJobForm.module.scss';
import { useTranslation } from 'react-i18next';

interface ICandidateJobFormProps {
  history: any;
  jobId: string;
}
interface ICandidateJobFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  recaptcha: string;
  website: string;
  linkedin: string;
  country: string;
  state: string;
  city: string;
}

const ValidationTextField = withStyles({
  root: {
    '& input': {
      background: '#ffff',
    },
  },
})(TextField);

const KJobFormTooltip = withStyles(() => ({
  tooltip: {
    width: 135,
  },
}))(Tooltip);

const CandidateJobForm: React.FC<
  ICandidateJobFormProps & FormikProps<ICandidateJobFormValues>
> = (props) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldValue,
    touched,
  } = props;
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const recaptchaRef = useRef<any>();
  const toggleCanSubmit = () => {
    if (canSubmit) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  };

  const applicantError = useSelector(sel_applicant_error);
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={8}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => store.dispatch(errorApplicant(''))}
        open={applicantError !== ''}
      >
        <KAlert severity="error">
          {t('Could not create Candidate')} ! -{' '}
          {applicantError.toString()}
        </KAlert>
      </Snackbar>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const token = await recaptchaRef.current.executeAsync();
          setFieldValue('recaptcha', token);
          if (token) {
            handleSubmit();
          }
          recaptchaRef.current.reset();
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ValidationTextField
              error={isError(errors, 'firstName', touched)}
              helperText={errors.firstName}
              label={t('Name')}
              name="firstName"
              onChange={handleChange}
              type="text"
              variant="outlined"
              id="validation-outlined-input"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ValidationTextField
              error={isError(errors, 'lastName', touched)}
              helperText={errors.lastName}
              label={t('Last Name')}
              name="lastName"
              onChange={handleChange}
              type="text"
              variant="outlined"
              id="validation-outlined-input"
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ValidationTextField
              error={isError(errors, 'phone', touched)}
              helperText={errors.phone}
              label={t('Phone')}
              name="phone"
              onChange={handleChange}
              type="text"
              variant="outlined"
              id="validation-outlined-input"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ValidationTextField
              error={isError(errors, 'email', touched)}
              helperText={errors.email}
              label="Email"
              name="email"
              onChange={handleChange}
              type="text"
              variant="outlined"
              id="validation-outlined-input"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <KCandidateLocation
              label={t('Country')}
              name="country"
              error={isError(errors, 'country', touched)}
              helperTextCountry={errors.country}
              helperTextState={errors.state}
              helperTextCity={errors.city}
              origin="vacancy"
            />
          </Grid>
          <Grid item xs={12}>
            <KFile
              accept=".pdf, .docx"
              name="resume"
              onChange={(ev: any) =>
                setFieldValue('resume', ev.currentTarget.files[0])
              }
            />
            <div className={styles.fileTypeText}>
              <p>
                {t('Only')} <b>PDF</b> {t('and')} <b>.docx</b>{' '}
                {t('files are accepted')}.
              </p>
              <p>
                {t('Max file size is')} <b>50MB</b>.
              </p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row">
              <KCheckbox
                onClick={toggleCanSubmit}
                tab={false}
                value={canSubmit}
              >
                {t("I am a text that support's links")}.
              </KCheckbox>
              <div className={styles.checkboxText}>
                <span>
                  {t('I have read and agreed to')}{' '}
                  <a
                    className={styles.checkboxLink}
                    href={
                      'https://www.theksquaregroup.com/privacy-policy/'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t("The Ksquare Group's privacy policy")}.
                  </a>
                </span>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} className={styles.inputContainer}>
            <ReCAPTCHA
              hl="en"
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA as string}
              size="invisible"
            />
          </Grid>
          <Grid item xs={12} className={styles.jobSubmit}>
            <KJobFormTooltip
              title={
                t('All fields are mandatory, as is your resume.') ||
                ''
              }
              placement="right"
            >
              <div className={styles.tooltipContainer}>
                <KButton
                  disabled={!canSubmit || !isValid || isSubmitting}
                  type="submit"
                  startIcon={
                    isSubmitting ? (
                      <KSpinner type="smallWhite" />
                    ) : null
                  }
                  className={styles.jobSubmitButton}
                >
                  {isSubmitting ? (
                    <span>{t('Uploading')}</span>
                  ) : (
                    <span>{t('Apply')}</span>
                  )}
                </KButton>
              </div>
            </KJobFormTooltip>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

const validationSchema = Yup.object({
  email: emailValidator,
  firstName: nameValidator,
  lastName: nameValidator,
  linkedin: urlValidator,
  phone: phoneRequiredValidator,
  website: urlValidator,
  country: countryRequiredValidator,
  state: stateRequiredValidator,
  city: cityRequiredValidator,
  //recaptcha: stringRequiredValidator,
});

const config = {
  mapPropsToValues: () => ({
    email: '',
    firstName: '',
    lastName: '',
    linkedin: '',
    phone: '',
    recaptcha: '',
    website: '',
    country: '',
    state: '',
    city: '',
  }),
  validationSchema,
  handleSubmit: async (
    values: ICandidateJobFormValues,
    formikBag: FormikBag<
      ICandidateJobFormProps,
      ICandidateJobFormValues
    >,
  ) => {
    const applicant = {
      ...values,
      jobId: formikBag.props.jobId,
    };
    try {
      await candidatesService.addNewApplicant(applicant, () => {
        // formikBag.props.history.push('/vacancy/applied');
        store.dispatch(setIsSended(true));
      });
    } catch (error) {
      store.dispatch(errorApplicant(error));
      // store.dispatch(setIsSended(false));
    }
  },
  displayName: 'Login',
};

export default withFormik<
  ICandidateJobFormProps,
  ICandidateJobFormValues
>(config)(CandidateJobForm);
