import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';

const vacancySlice = createSlice({
  name: 'vacancy',
  initialState: {
    isSended: false,
  },
  reducers,
});

export const { setIsSended } = vacancySlice.actions;

export default vacancySlice.reducer;
