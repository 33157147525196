import { IProfile } from 'modules/Users/typings';
import { apiClient } from './Client';

const editOne = (
  id: string,
  profile: IProfile,
): Promise<IProfile> => {
  return apiClient
    .put<IProfile>(`/profile/${id}`, { body: profile })
    .then((res: IProfile) => res);
};

export default {
  editOne,
};
