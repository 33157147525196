import React, { FC } from 'react';
import { addNewNote } from 'store/notes/thunks';
import { Box, Typography } from '@material-ui/core';
import { noteRequiredValidator } from 'utils/validations';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { KBaseContainer } from 'components';
import NoteForm from '../../Components/NoteForm';
import { useTranslation } from 'react-i18next';

const validationSchema = noteRequiredValidator;
interface INoteFormValues {
  noteComment: string;
}

const AddNote: FC<{}> = () => {
  const { candidateId: id } = useParams<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const candidateId = String(id);

  const handleSubmit = (values: INoteFormValues) => {
    const { noteComment } = values;
    const regexp = /data-id="(.*?)" data-value/g;
    const array = Array.from(noteComment.matchAll(regexp));
    let mentions = array.map((user) => user[1]);
    mentions = Array.from(new Set(mentions));

    dispatch(
      addNewNote({ candidateId, ...values, mentions }, () => {
        history.goBack();
      }),
    );
  };

  const { t } = useTranslation();

  return (
    <KBaseContainer whole>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mb={2}
      >
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          style={{ width: '100%' }}
        >
          {t('Add Note')}
        </Typography>
        <NoteForm
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        />
      </Box>
    </KBaseContainer>
  );
};

export default AddNote;
