import React, { useEffect } from 'react';
import {
  StylesProvider,
  ThemeProvider,
  CssBaseline,
} from '@material-ui/core';
import RootRouter from './rootRouter';
import theme from 'styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticatedAuth } from 'store/auth/authSlice';
import {
  selectorisAuthenticated,
  selectorAuthData,
} from 'store/auth/selectors';
import { KSnackbar } from 'components';

import {
  getUserProfile,
  userAgentApplication,
} from 'services/Microsoft/AuthProvider';
import { startSocket } from './services/socket.service';
import { sessionData } from 'utils/localStorage';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectorisAuthenticated);
  const authData = useSelector(selectorAuthData);

  useEffect(() => {
    const session = sessionData.getUser();
    if (!session) {
      dispatch(isAuthenticatedAuth(false));
    } else {
      // If MSAL already has an account, the user
      // is already logged in
      const account = userAgentApplication.getAccount();

      if (account) {
        // Enhance user object with data from Graph
        getUserProfile();
      } else {
        dispatch(isAuthenticatedAuth(false));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    const session = sessionData.getUser();
    if (isAuthenticated && session) {
      startSocket(session.token);
    } else if (isAuthenticated && authData) {
      startSocket(authData.token);
    }
  }, [authData, isAuthenticated]);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <KSnackbar />
        <CssBaseline />
        <RootRouter />
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
