import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { selectorAuthLoading } from 'store/auth/selectors';

import { useSelector, useDispatch } from 'react-redux';
import { Grid, Card, CardContent } from '@material-ui/core';
import KButton from 'components/KButton';
import KSpinner from 'components/KSpinner';

import { hasUserLocale } from 'utils/helpers';
import styles from './Login.module.scss';

import { login } from 'services/Microsoft/AuthProvider';
import { login as loginAction } from 'store/auth/thunks';
import { selectorisAuthenticated } from 'store/auth/selectors';

import slogan from 'img/slogan.png';
import dawoman from 'img/DaWoman.png';
import mslogo from 'img/MSLogo.png';
import variables from 'styles/_variables.module.scss';
import { useTranslation } from 'react-i18next';

const Login: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector(selectorAuthLoading);
  const isAuthenticated = useSelector(selectorisAuthenticated);
  const { t } = useTranslation();
  if (isAuthenticated && hasUserLocale()) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <div className={styles.header}>
        <img className={styles.slogan} src={slogan} alt="slogan" />
      </div>
      <div className={styles.login}>
        <div className={styles.ribbon} />
        <div className={styles.floatingOver}>
          <img
            src={dawoman}
            className={styles.dawoman}
            alt="dawoman"
          />
          <Card className={styles.card}>
            <CardContent>
              <div className={styles.welcome}>{t('Welcome')}</div>
              <div className={styles.to}>
                {t('to')}{' '}
                <span className={styles.kshire}>Ks Hire</span>
              </div>
              <Grid container spacing={3} justify="center">
                <KButton
                  onClick={() =>
                    dispatch(
                      loginAction(login, () => {
                        if (location.state) {
                          history.push(
                            (location.state as any).from.pathname,
                          );
                        } else {
                          history.push('/');
                        }
                      }),
                    )
                  }
                  className={styles.submit}
                >
                  {loading ? (
                    <KSpinner
                      size={20}
                      style={{ color: variables.primaryColorLogin }}
                    />
                  ) : (
                    <img
                      src={mslogo}
                      className={styles.mslogo}
                      alt="mslogo"
                    />
                  )}
                </KButton>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
