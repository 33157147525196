import { PayloadAction } from '@reduxjs/toolkit';

type State = {
  isSended: boolean;
};

export const setIsSended = (
  state: State,
  action: PayloadAction<boolean>,
) => {
  state.isSended = action.payload;
};

export default {
  setIsSended,
};
