import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, SnackbarCloseReason } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { setSnackbar } from 'store/snackbar/snackbarSlice';
import { sel_snackbar } from 'store/snackbar/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const KSnackbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { snackbarOpen, snackbarMessage, snackbarType } = useSelector(
    sel_snackbar,
  );

  const handleClose = (_: React.SyntheticEvent<Element, Event>) => {
    dispatch(
      setSnackbar({
        snackbarOpen: false,
        snackbarType: snackbarType,
        snackbarMessage: snackbarMessage,
      }),
    );
  };
  const handleCloseSnackbar = (
    _: React.SyntheticEvent<Element, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      dispatch(
        setSnackbar({
          snackbarOpen: false,
          snackbarType: snackbarType,
          snackbarMessage: snackbarMessage,
        }),
      );
    }
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          color={snackbarType}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default KSnackbar;
