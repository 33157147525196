import styles from './VersionCard.module.scss';
import React, {FC} from 'react';
import { IVersion } from './../../typings';

export type VersionCardProps = {
    version: IVersion;
};

const VersionCardModule: FC<VersionCardProps> = ({ version } ) => (<div className={styles.versionCard}>{ version.description }</div>)

export default VersionCardModule;