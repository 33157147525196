import { AppThunk } from 'store';
import {
  requestFetchAuth,
  authenticateUser,
  failFetchAuth,
} from 'store/auth/authSlice';
import authService from 'services/auth.service';
import { setSnackbar } from 'store/snackbar/snackbarSlice';
import { sessionData } from 'utils/localStorage';
import i18n from 'tranlations';
/* eslint-disable no-console */
console.log(authService);
export const login = (
  loginMs: () => Promise<string | undefined>,
  callback: () => void,
): AppThunk => async (dispatch) => {
  dispatch(requestFetchAuth(true));
  const token = await loginMs();
  authService
    .login(token as string)
    .then((res) => {
      dispatch(authenticateUser(res));
      dispatch(requestFetchAuth(false));
      sessionData.setUser(res);
      i18n.changeLanguage(res.user.locale);
      callback();
    })
    .catch((error) => {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'There was an error when trying to log in',
        }),
      );
      dispatch(requestFetchAuth(false));
      dispatch(
        failFetchAuth({
          message: error.statusText,
          status: error.status,
          error: true,
        }),
      );
    });
};
