import React, { FC } from 'react';
import {
  TextFieldProps,
  MenuItem,
  TextField,
} from '@material-ui/core';

export interface IOption {
  label: string;
  value: string | number;
}

export type KSelectProps = TextFieldProps & {
  options?: Array<IOption>;
};

const KSelect: FC<KSelectProps> = ({ options = [], ...props }) => {
  return (
    <TextField {...props} select>
      {options.map((option: IOption) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default KSelect;
