import { PayloadAction } from '@reduxjs/toolkit';
export interface State {
  snackbarOpen: boolean;
  snackbarType?: 'error' | 'success' | 'info' | 'warning';
  snackbarMessage: string;
}
interface SetSnackbar {
  snackbarOpen: boolean;
  snackbarType?: 'error' | 'success' | 'info' | 'warning';
  snackbarMessage: string;
}
export const setSnackbar = (
  state: State,
  action: PayloadAction<SetSnackbar>,
) => {
  state.snackbarOpen = action.payload.snackbarOpen;
  state.snackbarType = action.payload.snackbarType;
  state.snackbarMessage = action.payload.snackbarMessage;
};
export default {
  setSnackbar,
};
