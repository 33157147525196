import React, { FC } from 'react';
import versions from './versions.json';
import VersionCard from './Components/VersionCard';
import VersionCollapsible from './Components/VersionCollapsible/VersionCollapsible';
import { useTranslation } from 'react-i18next';
import styles from './VersionModule.module.scss';
import { IVersion } from './typings';

const VersionModule: FC = () => {

  const { t } = useTranslation();

  return (
    <>
    {
      versions.map((version: IVersion, index) => {
        return (
          index === 0 ?
            <div key={version.id.toString()} className={styles.versionLayout}>
              <div className={styles.container}>
                <div className={styles.updateReleased}>
                  {t("Update Released")} v{ version.version }
                </div>
                <div className={styles.date}>{version.date}</div>
                <span className={styles.upToDate}>Up to date</span>
                <div className={styles.versionCard}>
                  <VersionCard version={version} />
                </div>
              </div>
            </div>
          : (
              <div key={version.id.toString()}>
                {index === 1 ? <div className={styles.previousVersions}>{t("Previous Versions")}</div> : null}
                <VersionCollapsible version={version} index={index} />
              </div>
            )
        )
      })
    }
    </>
  );
}

export default VersionModule;