import React, { FC, useState, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import styles from './RoleSelector.module.scss';
import { useTranslation } from 'react-i18next';

interface IOption {
  label: string;
  value: string | number;
}

interface IRoleSelectorProps {
  name: any;
  value: any;
  onChange: any;
  options?: Array<IOption>;
}

const RoleSelector: FC<IRoleSelectorProps> = ({
  options = [],
  name,
  value,
  onChange,
}) => {
  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [rolePermissions, setRolePermissions] = useState<string[]>(
    [],
  );
  const { t } = useTranslation();

  useEffect(() => {
    let permisions = [];
    switch (value) {
      case '1':
        setRoleName(t('Administrator Role'));
        setRoleDescription(
          t(
            'Have access to the whole platform. This is the only role that can edit and change other Users role.',
          ),
        );
        permisions = [
          t('Stats: Visible on Dashboard.'),
          t('Users: Able to Edit.'),
          t('Jobs: Able to View, Create, Edit and Delete.'),
          t(
            'Candidates: Able to View, Create, Edit and Delete. Also for Notes, Feedback and Documents.',
          ),
        ];
        setRolePermissions(permisions);
        break;
      case '2':
        setRoleName(t('Recruiter Role'));
        setRoleDescription(
          t(
            'Able to View Create, Edit and Delete Candidates as well as Jobs.',
          ),
        );
        permisions = [
          t('Stats: Visible on Dashboard.'),
          t('Users: Has no access.'),
          t('Jobs: Able to View, Create, Edit and Delete.'),
          t(
            'Candidates: Able to View, Create, Edit and Delete. Also for Notes, Feedback and Documents.',
          ),
        ];
        setRolePermissions(permisions);
        break;
      case '3':
        setRoleName(t('Interviewer Role'));
        setRoleDescription(
          t('Most limited User. Only able to View Candidates.'),
        );
        permisions = [
          t('Stats: Has no access.'),
          t('Users: Has no access.'),
          t('Jobs: Has no access.'),
          t(
            'Candidates: Able to View. Able to Add Feedback and only visualize Documents.',
          ),
        ];
        setRolePermissions(permisions);
        break;
      case '4':
        setRoleName(t('Manager Role'));
        setRoleDescription(
          t(
            'Able to View, Create, Edit and Delete Jobs. Able to View and Create candidates.',
          ),
        );
        permisions = [
          t('Stats: Visible on Dashboard.'),
          t('Users: Has no access.'),
          t('Jobs: Able to View, Create, Edit and Delete.'),
          t(
            'Candidates: Able to View. Able to Add Feedback, Notes and Documents.',
          ),
        ];
        setRolePermissions(permisions);
        break;
      default:
        break;
    }
  }, [value, t]);

  return (
    <div className={styles.container}>
      <div className={styles.roles}>
        <FormControl component="fieldset">
          <RadioGroup name={name} value={value} onChange={onChange}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      <div className={styles.roleDescription}>
        <Typography variant="h5" gutterBottom>
          {roleName}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {roleDescription}
        </Typography>
        <ul>
          {rolePermissions.map((permission, i) => (
            <li key={i}>{permission}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RoleSelector;
