import React, { useState, useRef } from 'react';
import styles from './KFile.module.scss';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface KFileProps {
  name?: string;
  accept?: string;
  onChange: (ev: any) => void;
}

const KFile: React.FC<KFileProps> = ({ name, accept, onChange }) => {
  const [label, setLabel] = useState<string>('Upload Resume');
  const [fileName, setFileName] = useState<string>('');
  const [isFileUpload, setIsFileUpload] = useState<boolean>(false);
  const input = useRef<any>();

  const handleChange = (ev: any) => {
    const fileNameResume: string = ev.target?.files[0].name;
    setLabel('Replace Resume');
    setFileName(fileNameResume);
    setIsFileUpload(true);
    onChange(ev);
  };

  const handleClick = (ev: any) => {
    ev.preventDefault();
    input.current.value = '';
    setLabel('Upload Resume');
    setFileName('');
    setIsFileUpload(false);
  };

  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <input
        accept={accept}
        name={name}
        id="file"
        type="file"
        className={styles.kFile}
        onChange={handleChange}
        ref={input}
      />
      <label htmlFor="file" className={styles.kFileLabel}>
        <AttachFileIcon />
        <span style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          {t(label)}
        </span>
      </label>
      {isFileUpload ? (
        <>
          <p className={styles.kFileName}>{fileName}</p>
          <p className={styles.deleteFile} onClick={handleClick}>
            x
          </p>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default KFile;
