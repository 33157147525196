import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getRole } from 'utils/helpers';
import { INavigationRoutes, routes } from 'utils/navigation';
import styles from './KSidebar.module.scss';
import { useTranslation } from 'react-i18next';

interface IKSidebarProps {
  isOpen: boolean;
}

const KSidebar: React.FC<IKSidebarProps> = ({ isOpen }) => {
  const { t } = useTranslation();
  const KSideBarTooltip = withStyles(() => ({
    tooltip: {
      position: 'relative',
      right: 20,
      fontSize: 14,
    },
  }))(Tooltip);

  const filterRoutes = (route: INavigationRoutes) => {
    switch (route.name) {
      case 'Users':
        return (
          getRole() === 'ADMINISTRATOR' && (
            <ListItem
              key={route.name}
              button
              component={NavLink}
              exact
              className={styles.sidebarItem}
              activeClassName={styles.selected}
              to={route.path}
            >
              <KSideBarTooltip
                placement="right"
                title={t(isOpen ? '' : route.name) || ''}
              >
                <ListItemIcon>
                  <Icon>{route.icon}</Icon>
                </ListItemIcon>
              </KSideBarTooltip>
              <ListItemText primary={t(route.name)} />
            </ListItem>
          )
        );
      case 'Jobs':
        return (
          ['ADMINISTRATOR', 'RECRUITER', 'MANAGER'].includes(
            getRole(),
          ) && (
            <ListItem
              activeClassName={styles.selected}
              button
              className={styles.sidebarItem}
              component={NavLink}
              exact
              key={route.name}
              to={route.path}
            >
              <KSideBarTooltip
                placement="right"
                title={t(isOpen ? '' : route.name) || ''}
              >
                <ListItemIcon>
                  <Icon>{route.icon}</Icon>
                </ListItemIcon>
              </KSideBarTooltip>
              <ListItemText primary={t(route.name)} />
            </ListItem>
          )
        );
      case 'Candidates':
        return (
          [
            'ADMINISTRATOR',
            'RECRUITER',
            'MANAGER',
            'INTERVIEWER',
          ].includes(getRole()) && (
            <ListItem
              activeClassName={styles.selected}
              button
              className={styles.sidebarItem}
              component={NavLink}
              exact
              key={route.name}
              to={route.path}
            >
              <KSideBarTooltip
                placement="right"
                title={t(isOpen ? '' : route.name) || ''}
              >
                <ListItemIcon>
                  <Icon>{route.icon}</Icon>
                </ListItemIcon>
              </KSideBarTooltip>
              <ListItemText primary={t(route.name)} />
            </ListItem>
          )
        );
      default:
        return (
          <ListItem
            activeClassName={styles.selected}
            button
            className={styles.sidebarItem}
            component={NavLink}
            exact
            key={route.name}
            to={route.path}
          >
            <KSideBarTooltip
              placement="right"
              title={t(isOpen ? '' : route.name) || ''}
            >
              <ListItemIcon>
                <Icon>{route.icon}</Icon>
              </ListItemIcon>
            </KSideBarTooltip>
            <ListItemText primary={t(route.name)} />
          </ListItem>
        );
    }
  };
  return (
    <Drawer
      classes={{
        paper: isOpen ? styles.drawerOpen : styles.drawerClose,
      }}
      className={styles.KSidebar}
      variant="permanent"
    >
      <List className={styles.list}>
        {routes.map((route: INavigationRoutes) =>
          filterRoutes(route),
        )}
      </List>
    </Drawer>
  );
};

export default KSidebar;
