import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { IOptions } from './typings';
import { useTranslation } from 'react-i18next';

interface IEditIconsProps {
  feedbackId: string;
  options: IOptions[];
}

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}));

function EditIcons({ feedbackId, options }: IEditIconsProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const IconList = options.map((option: IOptions) =>
    option.type === 'DELETE' ? (
      <IconButton
        key={option.type}
        onClick={() => {
          option.deleteFn && option.deleteFn(feedbackId);
        }}
      >
        <Tooltip
          arrow
          classes={classes}
          placement="top"
          title={t(`Delete Feedback`) || ''}
        >
          <DeleteIcon />
        </Tooltip>
      </IconButton>
    ) : (
      <Link
        key={option.type}
        to={feedbackId + option.path}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <IconButton>
          <Tooltip
            arrow
            classes={classes}
            placement="top"
            title={t(`Edit Feedback`) || ''}
          >
            <EditIcon />
          </Tooltip>
        </IconButton>
      </Link>
    ),
  );

  return <div>{IconList}</div>;
}

export default EditIcons;
