import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { textFieldSelectTheme, textFieldTheme } from 'styles/theme';
import { useField } from 'formik';

export interface KPositionProps {
  options: [];
  error?: any;
  helperText?: string;
}

const KPosition: React.FC<any> = ({
  label,
  name,
  options = [],
  disabled = false,
  required,
  onChangeOpt,
  noOptionsText = 'No options',
  origin,
  error,
  helperText,
  ...props
}) => {
  const [field, , helpers] = useField(name);
  return (
    <MuiThemeProvider
      theme={
        origin === 'vacancy' ? textFieldSelectTheme : textFieldTheme
      }
    >
      <Autocomplete
        options={options}
        disabled={disabled}
        value={field.value}
        disableClearable
        noOptionsText={noOptionsText}
        onBlur={() => helpers.setTouched(true)}
        onChange={
          onChangeOpt
            ? onChangeOpt
            : (_: any, value: any) => {
                if (value) {
                  helpers.setValue(value);
                } else {
                  helpers.setValue('');
                }
              }
        }
        getOptionLabel={(option: any) => {
          if (option.name) {
            return option.name;
          } else {
            return '';
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            required={required}
            label={label}
            variant={origin === 'vacancy' ? 'outlined' : 'standard'}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </MuiThemeProvider>
  );
};

export default KPosition;
