import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  jobTitleRequiredValidator,
  richTextRequiredValidator,
  salaryRequiredValidator,
  salaryUpperRequiredValidator,
  stringRequiredValidator,
  nameValidator,
  nameWithNumbersValidator,
  numberRequiredValidator,
  numberValidator,
} from 'utils/validations';

import { getJob, editJob } from 'store/jobs/thunks';
import { sel_jobData } from 'store/jobs/selectors';
import { IJob } from 'modules/Jobs/typings';

import { IOption } from 'components/KMultiSelect/KMultiSelect';
import { KFieldGroup } from 'components/KForm/KForm';
import KBaseContainer from 'components/KBaseContainer';
import KButton from 'components/KButton';
import KForm from 'components/KForm';
import KModalCard from 'components/KModalCard';
import KSpinner from 'components/KSpinner';
import resumeImg from 'img/resume.svg';
import styles from './EditJob.module.scss';
import userService from 'services/users.service';
import { IUserListing } from 'modules/Users/typings';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object({
  details: richTextRequiredValidator,
  clientJobId: nameWithNumbersValidator,
  clientName: nameWithNumbersValidator,
  departmentId: numberRequiredValidator,
  jobSeniority: stringRequiredValidator,
  jobTime: stringRequiredValidator,
  jobType: stringRequiredValidator,
  jobUrgency: stringRequiredValidator,
  location: nameValidator,
  salaryCurrency: stringRequiredValidator,
  salarylower: salaryRequiredValidator,
  salaryPeriod: stringRequiredValidator,
  salaryupper: salaryUpperRequiredValidator,
  title: jobTitleRequiredValidator,
  requiredPositions: numberValidator,
});

const EditJob = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getJob(String(jobId)));
  }, [dispatch, jobId]);

  const {
    clientJobId,
    clientName,
    details,
    externalManager,
    hiringManagers,
    id,
    departmentId,
    jobSeniority,
    jobTime,
    jobType,
    jobUrgency,
    location,
    salaryCurrency,
    // salaryGross,
    salaryLower,
    salaryPeriod,
    salaryPublic,
    isJobRemote,
    salaryUpper,
    status,
    title,
    requiredPositions,
  }: IJob = useSelector(sel_jobData);

  const [users, setUsers] = useState<IUserListing[]>([]);

  const hiringManagersInitial = hiringManagers.map((manager) => ({
    id: manager.id,
    label: `${manager.firstName} ${manager.lastName}`,
  }));

  const initialComponents: KFieldGroup[] = [
    {
      name: t('Basics'),
      fields: [
        {
          fieldProps: {
            label: t('Job title'),
            name: 'title',
            required: true,
            options: [
              'Salesforce',
              'Fullstack Developer',
              'Java Developer',
              'Design',
              'Cybersecurity',
              'JavaScript Developer',
            ],
          },
          initialValue: title,
          type: 'autocomplete',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Job Department'),
            name: 'departmentId',
            required: true,
            options: [
              { value: 1, label: 'HR' },
              { value: 2, label: 'KSQU' },
              { value: 3, label: 'Marketing' },
              { value: 4, label: 'Operations' },
              { value: 5, label: 'Project Management' },
              { value: 6, label: 'Technology' },
              { value: 7, label: 'UX' },
            ],
          },
          initialValue: departmentId,
          initialValueSelector: jobSeniority,
          type: 'department',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Location'),
            name: 'location',
            required: true,
            options: [
              'Dallas, Texas, US',
              'Irving, Texas, US',
              'Hyderabad, Andhra Pradesh, IN',
              'Mérida, Yucatán, MX',
              'Mexico City, MX',
              'Santiago, DO',
            ],
          },
          initialValue: location,
          type: 'location',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Remote'),
            name: 'isJobRemote',
            option: t('Remote'),
            tab: true,
          },
          initialValue: isJobRemote,
          type: 'checkbox',
          layoutSize: 2,
        },
        {
          fieldProps: {
            label: t('Job type'),
            name: 'jobType',
            required: true,
            options: [
              { value: 'Permanent', label: t('Permanent') },
              { value: 'Temporal', label: t('Temporal') },
            ],
          },
          initialValue: jobType,
          type: 'select',
          layoutSize: 2,
        },
        {
          fieldProps: {
            label: t('Job Urgency'),
            name: 'jobUrgency',
            required: true,
            options: [
              { value: 'High', label: t('High') },
              { value: 'Mid', label: t('Mid') },
              { value: 'Low', label: t('Low') },
            ],
          },
          initialValue: jobUrgency,
          type: 'select',
          layoutSize: 2,
        },

        {
          fieldProps: {
            label: t('Job time'),
            name: 'jobTime',
            required: true,
            options: [
              { value: 'Full-time', label: t('Full-time') },
              { value: 'Part-time', label: t('Part-time') },
            ],
          },
          initialValue: jobTime,
          type: 'select',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Job Status'),
            name: 'status',
            required: true,
            options: [
              { value: 'Open', label: t('Open') },
              { value: 'Closed', label: t('Closed') },
            ],
          },
          initialValue: status,
          type: 'select',
          layoutSize: 3,
        },
        {
          fieldProps: {
            label: t('Required Positions'),
            name: 'requiredPositions',
          },
          initialValue: requiredPositions,
          type: 'text',
          layoutSize: 3,
        },
        {
          fieldProps: {
            label: t('Salary currency'),
            name: 'salaryCurrency',
            required: true,
            options: [
              { value: 'DOP', label: 'DOP' },
              { value: 'INR', label: 'INR' },
              { value: 'MXN', label: 'MXN' },
              { value: 'USD', label: 'USD' },
            ],
          },
          initialValue: salaryCurrency,
          type: 'select',
          layoutSize: 2,
        },
        {
          fieldProps: {
            label: t('Salary range'),
            name: 'salary',
            required: true,
          },
          initialValue: { lower: salaryLower, upper: salaryUpper },
          type: 'range',
          layoutSize: 4,
        },
        // {
        //   fieldProps: {
        //     label: 'Salary Type',
        //     name: 'salaryGross',
        //     required: true,
        //     options: [
        //       { value: 'Gross', label: 'Gross' },
        //       { value: 'Net', label: 'Net' },
        //     ],
        //   },
        //   initialValue: salaryGross,
        //   type: 'select',
        //   layoutSize: 2,
        // },
        {
          fieldProps: {
            label: t('Salary period'),
            name: 'salaryPeriod',
            required: true,
            options: [
              { value: 'year', label: t('Annually') },
              { value: 'month', label: t('Monthly') },
              { value: 'hour', label: t('Hourly') },
            ],
          },
          initialValue: salaryPeriod,
          type: 'select',
          layoutSize: 3,
        },
        {
          fieldProps: {
            label: t('Public'),
            name: 'salaryPublic',
            option: t('Public salary'),
            tab: true,
          },
          initialValue: salaryPublic,
          type: 'checkbox',
          layoutSize: 3,
        },
        {
          fieldProps: {
            label: t('Client'),
            name: 'clientName',
          },
          initialValue: clientName,
          type: 'text',
          layoutSize: 6,
        },
        {
          fieldProps: {
            label: t('Client Job ID'),
            name: 'clientJobId',
          },
          initialValue: clientJobId,
          type: 'text',
          layoutSize: 6,
        },
      ],
    },
    {
      name: t('Description'),
      fields: [
        {
          fieldProps: { label: t('Description'), name: 'details' },
          initialValue: details,
          type: 'textRich',
          layoutSize: 12,
        },
      ],
    },
    {
      name: t('Hiring Team'),
      fields: [
        {
          fieldProps: {
            label: t('External'),
            name: 'externalManager',
            option: t('External manager'),
            tab: true,
            options: [],
          },
          initialValue: externalManager,
          initialValueSelector: hiringManagersInitial,
          type: 'externalManager',
          layoutSize: 12,
        },
      ],
    },
  ];

  const [components, setComponents] = useState<KFieldGroup[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      await userService.getAll().then((res) => setUsers(res));
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (users.length > 0 && id === jobId) {
      const interviewersOptions = users
        .filter((user) =>
          ['MANAGER', 'ADMINISTRATOR'].includes(user.role.level),
        )
        .map((interviewer) => ({
          id: interviewer.id,
          label: `${interviewer.firstName} ${interviewer.lastName}`,
        }));
      const updatedComponents = initialComponents;
      updatedComponents[2].fields[0].fieldProps.options = interviewersOptions;
      setComponents(updatedComponents);
    }
    //eslint-disable-next-line
  }, [users, id]);

  const handleSubmit = (values: any) => {
    const {
      clientJobId,
      clientName,
      departmentId,
      details,
      externalManager,
      hiringManagers,
      isJobRemote,
      jobSeniority,
      jobTime,
      jobType,
      jobUrgency,
      location,
      salaryCurrency,
      // salaryGross,
      salarylower,
      salaryPeriod,
      salaryPublic,
      salaryupper,
      status,
      title,
      requiredPositions,
    } = values;
    const response = {
      clientJobId,
      clientName,
      departmentId,
      details,
      externalManager,
      isJobRemote,
      jobSeniority,
      jobTime,
      jobType,
      jobUrgency,
      location,
      salaryCurrency,
      salaryGross: 'Gross',
      salaryPeriod,
      salaryPublic,
      status,
      publicApproval: 'Not Approved',
      requiredPositions,
      title,
      hiringManagers: externalManager
        ? []
        : hiringManagers.map((value: IOption) => value.id),
      salaryLower: !salarylower.includes(',')
        ? salarylower.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : salarylower,

      salaryUpper: !salaryupper.includes(',')
        ? salaryupper.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : salaryupper,
    };
    if (id !== undefined && id !== null) {
      dispatch(editJob(jobId || '', response, setOpen));
    }
  };

  const modalContent = (
    <div className={styles.modal}>
      <h2>{t('Job Updated')}</h2>
      <img src={resumeImg} alt="Job updated" />
      <p>{t('Job successfully updated')}</p>
      <div className={styles.buttons}>
        <KButton
          className={styles.button}
          variant="outlined"
          onClick={() => {
            history.push('/jobs');
          }}
        >
          {t('DONE')}
        </KButton>
        <KButton
          className={styles.button}
          onClick={() => {
            history.push(`/jobs/${id}`);
          }}
        >
          {t('VIEW JOB')}
        </KButton>
      </div>
    </div>
  );

  return (
    <KBaseContainer>
      <KModalCard open={open}>{modalContent}</KModalCard>

      {id === jobId && components.length !== 0 ? (
        <KForm
          fieldsGroups={components}
          name={t('Edit Job')}
          validationSchema={validationSchema}
          onSuccess={handleSubmit}
          buttonText={t('UPDATE')}
        />
      ) : (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingAnimation}>
            <KSpinner type="bigThick" />
          </div>
          <div className={styles.loadingText}>
            <p>{t('LOADING JOBS...')}</p>
          </div>
        </div>
      )}
    </KBaseContainer>
  );
};

export default EditJob;
