import React, { FC } from 'react';
import styles from './VersionCollapsible.module.scss';
import VersionCard from './../VersionCard/VersionCard';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const VersionCollapsible: FC<any> = ({version, index}) => (
    <div>
        <Accordion className={`${styles.accordion} ${index === 1 ? styles.firstBorder : styles.border}`}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={styles.expandIcon}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography component={'span'} className={styles.typography}>
                    <span className={styles.typographyVersion}>v{version.version}</span>
                    <span className={styles.typographyDate}>{version.date}</span>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component={'span'} className={styles.versionCollapsible}>
                    <VersionCard version={version} />
                </Typography>
            </AccordionDetails>
        </Accordion>
    </div>
)


export default VersionCollapsible;