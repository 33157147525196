import * as Yup from 'yup';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';

import { stringRequiredValidator } from 'utils/validations';
import { addNewFeedback } from 'store/feedbacks/thunks';
import { KBaseContainer } from 'components';
import RatingForm from '../../Components/FeedbackForm/RatingForm';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object({
  comment: stringRequiredValidator,
});

interface IFeedbackFormValues {
  score: number;
  comment: string;
}

const EditFeedback: FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { candidateId: id } = useParams();
  const candidateId = String(id);

  const handleSubmit = (values: IFeedbackFormValues) => {
    dispatch(
      addNewFeedback({ candidateId, ...values }, () => {
        history.goBack();
      }),
    );
  };
  const { t } = useTranslation();

  return (
    <KBaseContainer whole>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mb={2}
      >
        <Typography variant="h6" gutterBottom align="center">
          {t('Add Feedback')}
        </Typography>
        <RatingForm
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{ comment: '', score: 0 }}
        />
      </Box>
    </KBaseContainer>
  );
};

export default EditFeedback;
