import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import es from './es.json';
import { sessionData } from 'utils/localStorage';

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: sessionData.getUser()?.user.locale || 'en',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
  nsSeparator: false,
});

export default i18n;
