import io from 'socket.io-client';
import store from 'store';
import { getNotifications } from 'store/notifications/thunks';
import { INotification } from '../modules/Users/views/Dashboard/typings';
import notificationSound from 'img/audio/notification.ogg';

const audio = new Audio(notificationSound);

export function startSocket(token: string) {
  const { REACT_APP_API_URL } = process.env;

  const socket = io(`${REACT_APP_API_URL}`, {
    query: {
      token: token,
    },
    transports: ['websocket'],
    upgrade: false,
  });

  function requestNotificationPermissions() {
    if (Notification.permission !== 'denied') {
      Notification.requestPermission(function () {
        // console.log(permission);
      });
    }
  }
  requestNotificationPermissions();

  socket.on('connect', () => {
    // console.log('websocket connected');
  });
  socket.on('change', async () => {
    await store.dispatch(getNotifications());
    const notification = store.getState().notifications.all[0];
    if (notification) {
      audio.pause();
      audio.currentTime = 0;
      audio.play();
      new Notification((notification as INotification)?.type, {
        body: (notification as INotification)?.message,
        tag: 'notification-kshire',
      });
    }
  });
  socket.on('disconnect', () => {
    // console.log(reason);
  });
}

export default {
  startSocket,
};
