import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  nameValidator,
  stringRequiredValidator,
} from 'utils/validations';
import { IUser } from 'modules/Users/typings';
import { getUser, editUser } from 'store/users/thunks';
import { sel_userData } from 'store/users/selectors';
import { KFieldGroup } from 'components/KForm/KForm';
import KBaseContainer from 'components/KBaseContainer';
import KForm from 'components/KForm';
import KSpinner from 'components/KSpinner';
import styles from './EditUser.module.scss';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object({
  firstName: nameValidator,
  lastName: nameValidator,
  roleId: stringRequiredValidator,
});

const UserEdit = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, firstName, lastName, roleId }: IUser = useSelector(
    sel_userData,
  );

  const handleSubmit = (values: any) => {
    if (id !== undefined && id !== null) {
      values.roleId = Number(values.roleId);
      dispatch(
        editUser(id, values, () => {
          history.push('/users');
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(getUser(String(userId)));
    //eslint-disable-next-line
  }, []);
  const { t } = useTranslation();

  const components: KFieldGroup[] = [
    {
      name: t('User information'),
      fields: [
        {
          fieldProps: { label: t('First Name'), name: 'firstName' },
          initialValue: firstName,
          type: 'text',
          layoutSize: 6,
        },
        {
          fieldProps: { label: t('Last Name'), name: 'lastName' },
          initialValue: lastName,
          type: 'text',
          layoutSize: 6,
        },
      ],
    },
    {
      name: t('Choose role'),
      fields: [
        {
          fieldProps: {
            name: 'roleId',
            options: [
              { value: '1', label: t('Administrator') },
              { value: '2', label: t('Recruiter') },
              { value: '4', label: t('Manager') },
              { value: '3', label: t('Interviewer') },
            ],
          },
          initialValue: String(roleId),
          type: 'roleSelector',
          layoutSize: 12,
        },
      ],
    },
  ];
  return (
    <KBaseContainer>
      {userId === id ? (
        <KForm
          name={t('Edit User')}
          fieldsGroups={components}
          validationSchema={validationSchema}
          onSuccess={handleSubmit}
          buttonText={t('Update')}
        />
      ) : (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingAnimation}>
            <KSpinner type="bigThick" />
          </div>
          <div className={styles.loadingText}>
            <p>{t('LOADING USERS...')}</p>
          </div>
        </div>
      )}
    </KBaseContainer>
  );
};

export default UserEdit;
