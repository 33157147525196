import { createMuiTheme } from '@material-ui/core';
import variables from './_variables.module.scss';
export const SPACING_UNIT = 8;

export default createMuiTheme({
  props: {
    MuiTextField: {
      variant: 'standard',
      fullWidth: true,
    },
  },
  typography: {
    fontFamily: '"Nunito", Arial, sans-serif',
  },
  palette: {
    primary: {
      light: variables.hoverColor,
      main: variables.primaryColor,
      dark: variables.focusColor,
      contrastText: variables.white,
    },
    secondary: {
      main: variables.hoverColor,
    },
    background: {
      default: variables.backgroundContainer,
    },
  },
  spacing: SPACING_UNIT,
  overrides: {
    MuiButton: {
      root: {
        padding: '11px 26px',
        textTransform: 'capitalize',
        fontWeight: Number(variables.fontWeightExtraBold),
      },
    },
    MuiTypography: {
      body2: {
        color: variables.fontColor,
        fontWeight: 'bold',
      },
      h1: {
        fontSize: '2em',
        fontWeight: Number(variables.fontWeightExtraBold),
      },
      h5: {
        color: variables.fontColor,
        fontWeight: 'bold',
      },
      subtitle2: {
        color: '#ababab',
        fontWeight: 'bold',
        marginBottom: '0.125rem',
      },
    },
    MuiTablePagination: {
      toolbar: {
        '& .MuiTypography-body2': {
          color: '#242528',
          fontWeight: '400',
        },
        '& .MuiSelect-select.MuiSelect-select': {
          color: '#242528',
          fontWeight: '400',
        },
        '& .MuiSvgIcon-root': {
          color: '#242528',
          fontWeight: '400',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: '#f44336',
          textAlign: 'left',
        },
      },
    },
  },
});

export const textFieldTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      input: {
        color: variables.fontColor,
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${variables.fontColor}`,
        },
        '&:after': {
          borderBottom: `1px solid ${variables.primaryColor}`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${variables.primaryColor}`,
        },
        '&$disabled:before': {
          borderBottom: `1px solid ${variables.disabledColor}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: variables.fontColor,
        fontSize: '12px/16px',
        // increase the specificity for the pseudo class
        '&$focused': {
          color: variables.fontColor,
        },
        '&$disabled': {
          color: variables.disabledColor,
        },
        '&$error': {
          color: variables.fontColor,
        },
      },
      asterisk: {
        color: variables.fontColor,
      },
    },
    MuiInputBase: {
      input: {
        '&$disabled': {
          color: variables.disabledColor,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: '#f44336',
          textAlign: 'left',
        },
      },
    },
    MuiInputAdornment: {
      root: {
        alignItems: 'center',
      },
      positionEnd: {
        marginLeft: '130px',
      },
    },
    MuiFilledInput: {
      input: {
        borderBottom: `1px solid ${variables.fontColor}`,
        color: variables.fontColor,
        font: '16px/22px Nunito Sans',
        maxWidth: '223px',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${variables.fontColor}`,
          maxWidth: '247px',
        },
        '&:after': {
          borderBottom: `1px solid ${variables.primaryColor}`,
          maxWidth: '247px',
        },
        '&:hover:before': {
          borderBottom: `1px solid ${variables.primaryColor}`,
          maxWidth: '247px',
        },
      },
    },
    // MuiOutlinedInput: {
    //   root: {
    //     background: 'white',
    //   },
    // },
  },
});

export const timePickerTheme = createMuiTheme({
  palette: {
    primary: {
      main: variables.focusColor,
    },
  },
  overrides: {
    MuiInput: {
      input: {
        color: variables.fontColor,
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${variables.fontColor}`,
        },
        '&:after': {
          borderBottom: `1px solid ${variables.primaryColor}`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${variables.primaryColor}`,
        },
        '&$disabled:before': {
          borderBottom: `1px solid ${variables.disabledColor}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: variables.fontColor,
        fontSize: '12px/16px',
        // increase the specificity for the pseudo class
        '&$focused': {
          color: variables.fontColor,
        },
        '&$disabled': {
          color: variables.disabledColor,
        },
        '&$error': {
          color: variables.fontColor,
        },
      },
      asterisk: {
        color: variables.fontColor,
      },
    },
    MuiInputBase: {
      input: {
        '&$disabled': {
          color: variables.disabledColor,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: variables.primaryColor,
          textAlign: 'left',
        },
      },
    },

    MuiFilledInput: {
      input: {
        borderBottom: `1px solid ${variables.fontColor}`,
        color: variables.fontColor,
        font: '16px/22px Nunito Sans',
        maxWidth: '223px',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${variables.fontColor}`,
          maxWidth: '247px',
        },
        '&:after': {
          borderBottom: `1px solid ${variables.primaryColor}`,
          maxWidth: '247px',
        },
        '&:hover:before': {
          borderBottom: `1px solid ${variables.primaryColor}`,
          maxWidth: '247px',
        },
      },
    },
  },
});

export const multiSelectorTheme = createMuiTheme({
  palette: {
    primary: {
      main: variables.focusColor,
    },
  },
  overrides: {
    MuiInput: {
      input: {
        color: variables.fontColor,
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${variables.fontColor}`,
        },
        '&:after': {
          borderBottom: `1px solid ${variables.primaryColor}`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${variables.primaryColor}`,
        },
        '&$disabled:before': {
          borderBottom: `1px solid ${variables.disabledColor}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: variables.fontColor,
        fontSize: '12px/16px',
        // increase the specificity for the pseudo class
        '&$focused': {
          color: variables.fontColor,
        },
        '&$disabled': {
          color: variables.disabledColor,
        },
        '&$error': {
          color: variables.fontColor,
        },
      },
      asterisk: {
        color: variables.fontColor,
      },
    },
    MuiInputBase: {
      input: {
        '&$disabled': {
          color: variables.disabledColor,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: variables.primaryColor,
          textAlign: 'left',
        },
      },
    },

    MuiFilledInput: {
      input: {
        borderBottom: `1px solid ${variables.fontColor}`,
        color: variables.fontColor,
        font: '16px/22px Nunito Sans',
        maxWidth: '223px',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${variables.fontColor}`,
          maxWidth: '247px',
        },
        '&:after': {
          borderBottom: `1px solid ${variables.primaryColor}`,
          maxWidth: '247px',
        },
        '&:hover:before': {
          borderBottom: `1px solid ${variables.primaryColor}`,
          maxWidth: '247px',
        },
      },
    },
  },
});

export const textFieldSelectTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        background: 'white',
        '&$focused $notchedOutline': {
          borderWidth: 2,
          borderColor: variables.primaryColor,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#49a7b7',
        },
      },
    },
  },
});
