import { httpClient } from 'utils/httpClient';
import { sessionData } from 'utils/localStorage';

const { REACT_APP_API_URL } = process.env;
const path = '/api/v1';
/* eslint-disable no-console */
console.log('Debugging test: ', `${REACT_APP_API_URL}${path}`);

export const apiClient = httpClient.create({
  baseURL: `https://devhireapi.theksquaregroup.com/api/v1`,
  headers: {
    'content-type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const userJSON = sessionData.getUser();

    config.headers.authorization = `Bearer ${userJSON?.token || ''}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
