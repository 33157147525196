import React from 'react';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import variables from 'styles/_variables.module.scss';

const PurpleSwitch = withStyles({
  switchBase: {
    color: variables.rejectedStage,
    '&$checked': {
      color: variables.hiredStage,
    },
    '&$checked + $track': {
      backgroundColor: variables.hiredStage,
    },
  },
  checked: {},
  track: {},
})(Switch);

interface IKSwitchProps extends SwitchProps {
  label?: string;
}

const KSwitch: React.FC<IKSwitchProps> = (props) => (
  <FormControlLabel
    control={<PurpleSwitch {...props} />}
    label={props.label}
  />
);
export default KSwitch;
