import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteNote } from 'store/notes/thunks';
import { INoteRead } from 'modules/Candidates/typings';
import { Link } from 'react-router-dom';
import { KButton, KModalCard } from 'components';
import NotesList from './partials/NotesList';
import styles from './Note.module.scss';
import { useTranslation } from 'react-i18next';

interface INoteProps {
  candidateId: string | null;
  notes: INoteRead[];
}

const Note: FC<INoteProps> = ({ candidateId, notes }: INoteProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const dispatch = useDispatch();

  const url = `/candidates/${candidateId}/add-note`;

  const handleDelete = () => {
    if (!currentId) return;
    dispatch(deleteNote(currentId));
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setCurrentId(null);
    setOpenModal(false);
  };

  const { t } = useTranslation();

  return (
    <div className={styles.notesContainer}>
      <KModalCard
        onClose={handleCloseModal}
        open={openModal}
        type={'confirmation'}
        confirmFn={handleDelete}
        confirmMsg={t('You will not be able to recover this note!')}
        confirmBtn={t('Delete')}
        setOpenModal={setOpenModal}
      />

      <Link className={styles.addNoteButton} to={url}>
        <KButton className={styles.addNoteButton}>
          {t('ADD NOTE')}
        </KButton>
      </Link>

      <div className="notes-container">
        <NotesList
          notes={notes}
          setOpen={setOpenModal}
          setCurrentId={setCurrentId}
        />
      </div>
    </div>
  );
};
export default Note;
