import { ICandidate } from 'modules/Candidates/typings';
import { IAddCandidate } from './typings';
import { apiClient } from './Client';
import { sessionData } from 'utils/localStorage';

const addNew = async (
  candidate: IAddCandidate,
): Promise<ICandidate> => {
  const userJSON = sessionData.getUser();
  const { REACT_APP_API_URL } = process.env;

  const formData = new FormData();
  formData.append('firstName', candidate.firstName);
  formData.append('lastName', candidate.lastName);
  formData.append('email', candidate.email);
  formData.append('jobId', candidate.jobId);
  formData.append('linkedin', candidate.linkedin);
  formData.append('phone', candidate.phone);
  formData.append('referral', candidate.referral as string);
  formData.append('source', candidate.source as string);
  formData.append('stageId', candidate.stageId as string);
  formData.append('salaryOffer', candidate.salaryOffer as string);
  formData.append('website', candidate.website);
  formData.append(
    'firstContact',
    JSON.stringify(candidate.firstContact),
  );
  formData.append(
    'techInterview1',
    JSON.stringify(candidate.techInterview1),
  );
  formData.append(
    'techInterview2',
    JSON.stringify(candidate.techInterview2),
  );
  formData.append('type', candidate.resume.type);
  formData.append('recruiterId', candidate.recruiterId as string);
  formData.append('resume', candidate.resume);
  formData.append('country', candidate.country);
  formData.append('state', candidate.state);
  formData.append('city', candidate.city);

  const res: Response = await fetch(
    `${REACT_APP_API_URL}/api/v1/candidate`,
    {
      headers: {
        authorization: `Bearer ${userJSON?.token || ''}`,
      },
      method: 'POST',
      body: formData,
    },
  );

  if (!res.ok) {
    throw await res.json();
  }
  const doc: ICandidate = await res.json();
  return doc;
};

const addNewApplicant = async (
  candidate: IAddCandidate,
  callback: () => any,
): Promise<ICandidate> => {
  const { REACT_APP_API_URL } = process.env;

  const formData = new FormData();
  formData.append('firstName', candidate.firstName);
  formData.append('lastName', candidate.lastName);
  formData.append('phone', candidate.phone);
  formData.append('email', candidate.email);
  formData.append('website', candidate.website);
  formData.append('linkedin', candidate.linkedin);
  formData.append('jobId', candidate.jobId);
  formData.append('type', candidate.resume?.type);
  formData.append('resume', candidate.resume);
  formData.append('country', JSON.stringify(candidate.country));
  formData.append('state', JSON.stringify(candidate.state));
  formData.append('city', JSON.stringify(candidate.city));

  const res: Response = await fetch(
    `${REACT_APP_API_URL}/api/v1/candidate/apply`,
    {
      headers: {
        authorization: candidate.recaptcha,
      },
      method: 'POST',
      body: formData,
    },
  );

  if (!res.ok) {
    throw await res.json();
  }

  callback();

  return await res.json();
};

const deleteOne = (id: string) => {
  return apiClient.delete<ICandidate>(`/candidate/${id}`);
};

const editOne = (
  id: string,
  candidate: ICandidate,
): Promise<ICandidate> => {
  return apiClient
    .put<ICandidate>(`/candidate/${id}`, { body: candidate })
    .then((res: ICandidate) => res);
};

const getAll = (
  filter?: string,
  order?: string,
  orderBy?: string,
  rowsPerPage?: number,
  page?: number,
  search?: string,
  myCandidates: boolean = false,
): Promise<{
  count: number;
  rows: ICandidate[];
}> => {
  let endpoint = '/candidate?';

  if (filter) {
    endpoint += `filter=${filter}&`;
  }

  if (search) {
    endpoint += `search=${search}&`;
  }

  if (rowsPerPage !== undefined && page !== undefined) {
    endpoint += `limit=${rowsPerPage}&offset=${rowsPerPage * page}&`;
  }

  if (order && orderBy) {
    endpoint += `order=${order}&orderBy=${orderBy}&`;
  }

  if (myCandidates) {
    const user = sessionData.getUser()?.user;
    endpoint += `userId=${user?.id || ''}&`;
  }

  return apiClient
    .get<ICandidate[]>(endpoint)
    .then((res: any) => res);
};

const getCandidateStatistics = (
  myCandidates: boolean = false,
): Promise<any> => {
  const user = sessionData.getUser()?.user;
  return apiClient
    .get<ICandidate[]>(
      '/candidate/statistics' +
        (myCandidates ? `?userId=${user?.id || ''}` : ''),
    )
    .then((res: Array<ICandidate>) => res);
};

const getById = (id: string): Promise<ICandidate> => {
  return apiClient
    .get<ICandidate>(`/candidate/${id}`)
    .then((res: ICandidate) => res);
};

export default {
  addNew,
  addNewApplicant,
  deleteOne,
  editOne,
  getAll,
  getById,
  getCandidateStatistics,
};
